import HeroApiHelper from '../../helpers/axios'

class Service extends HeroApiHelper {
  list(params, authToken) {
    return super.get(`admin/product`, params, authToken)
  }

  get(id, authToken) {
    return super.get(`admin/product/${id}`, {}, authToken)
  }

  update(id, fieldData, authToken) {
    const {title, keywords, description, ranking, propertyReleased, modelReleased, isActive, isRestricted, notes, adobeCategory, width, height, isFreePremiumDownload} = fieldData
    return super.patch(`/admin/product/${id}`, {title, keywords, description, ranking, propertyReleased, modelReleased, isActive, isRestricted, notes, adobeCategory, width, height, isFreePremiumDownload}, authToken)
  }

  delete(id, authToken) {
    return super.delete(`/admin/product/${id}`, authToken)
  }

  rank(photoshootId, productIds, rank, authToken){
    return super.put(`/admin/product/rank`, {photoshootId, productIds, rank}, authToken)
  }
}

export default new Service()