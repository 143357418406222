import actions from "./actions";

export default function reducer(state = {
  modelSearch: undefined,
  modelResults: [],
  propertySearch: undefined,
  propertyResults: [],
  photographers: [],
  artDirectors: [],
  verticals: [],
  photoshoots: [],
  editPhotoshoot: undefined,
  info: undefined,
  type: undefined,
  error: undefined
}, action) {
  let actionIndex
  let photoshoots

  switch (action.type) {
    case actions.LIST_MODELS_SUCCESS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        modelSearch: action.info.search,
        modelResults: action.modelResults
      };
    case actions.LIST_MODELS_ERROR:
      return {
        ...state,
        type: action.type,
        error: action.error,
        modelSearch: undefined,
        modelResults: []
      };
    case actions.LIST_PROPERTIES_SUCCESS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        propertySearch: action.info,
        propertyResults: action.propertyResults
      };
    case actions.LIST_PROPERTIES_ERROR:
      return {
        ...state,
        type: action.type,
        error: action.error,
        propertySearch: undefined,
        propertyResults: []
      };
    case actions.LIST_PHOTOGRAPHERS_SUCCESS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        photographers: action.photographers
      };
    case actions.LIST_PHOTOGRAPHERS_ERROR:
      return {
        ...state,
        type: action.type,
        error: action.error,
        photographers: []
      };
    case actions.LIST_ART_DIRECTORS_SUCCESS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        artDirectors: action.artDirectors
      };
    case actions.LIST_ART_DIRECTORS_ERROR:
      return {
        ...state,
        type: action.type,
        error: action.error,
        artDirectors: []
      };
    case actions.LIST_VERTICALS_SUCCESS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        verticals: action.verticals
      };
    case actions.LIST_VERTICALS_ERROR:
      return {
        ...state,
        type: action.type,
        error: action.error,
        verticals: []
      };
    case actions.LIST_SUCCESS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        photoshoots: action.photoshoots,
        info: action.info
      };
    case actions.LIST_ERROR:
      return {
        ...state,
        type: action.type,
        error: action.error,
        photoshoots: [],
        info: undefined
      };
    case actions.GET:
      return {
        ...state,
        editPhotoshoot: undefined
      };
    case actions.GET_SUCCESS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        info: action.info,
        editPhotoshoot: action.editPhotoshoot
      };
    case actions.DELETE_SUCCESS:
      actionIndex = state.photoshoots.findIndex(p => p.id === action.id)
      photoshoots = [...state.photoshoots]
      if (actionIndex !== -1) {
        photoshoots.splice(actionIndex, 1)
      }
      return {
        ...state,
        type: undefined,
        error: undefined,
        photoshoots
      };
    case actions.GET_ERROR:
      return {
        ...state,
        type: action.type,
        error: action.error,
        editPhotoshoot: undefined
      };
    case actions.IMPORT:
    case actions.EXPORT_FORMAT:
    case actions.EXPORT_TEMPLATE:
    case actions.CREATE:
    case actions.UPDATE:
      return {
        ...state,
        loading: true
      }
    case actions.IMPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.info
      }
    case actions.EXPORT_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case actions.CREATE_SUCCESS:
    case actions.UPDATE_SUCCESS:
      if (state.editPhotoshoot) {
        action.editPhotoshoot.photoshootProducts = [...state.editPhotoshoot.photoshootProducts]
        action.editPhotoshoot.thumbnail = {...state.editPhotoshoot.thumbnail}
      }
      return {
        ...state,
        loading: false,
        type: undefined,
        error: undefined,
        editPhotoshoot: action.editPhotoshoot
      };
    case actions.RELOAD:
      return {
        ...state,
        type: undefined,
        error: undefined,
        editPhotoshoot: Object.assign({}, state.editPhotoshoot, {
          photoshootProducts: undefined
        })
      };
    case actions.SET_FEATURE_SUCCESS:
      const photoshootProducts = state.editPhotoshoot.photoshootProducts.map(product => {
        if (action.unfeaturedProducts.includes(product.id))
          product = {...product, isFeatured: 0}
        if (action.featuredProductId === product.id)
          product = {...product, isFeatured: 1}
        return product
      })
      return {
        ...state,
        type: undefined,
        error: undefined,
        editPhotoshoot: Object.assign({}, state.editPhotoshoot, {
          photoshootProducts,
          thumbnail: photoshootProducts.find(product => action.featuredProductId === product.id)
        })
      };
    case actions.CRUD_ERROR:
      return {
        ...state,
        type: action.type,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
}
