const actions = {
  LIST: 'GALLERY_LIST',
  LIST_SUCCESS: 'GALLERY_LIST_SUCCESS',
  LIST_ERROR: 'GALLERY_LIST_ERROR',
  GET: 'GALLERY_GET',
  GET_SUCCESS: 'GALLERY_GET_SUCCESS',
  GET_ERROR: 'GALLERY_GET_ERROR',
  CREATE: 'GALLERY_CREATE',
  CREATE_SUCCESS: 'GALLERY_CREATE_SUCCESS',
  CRUD_ERROR: 'GALLERY_CRUD_ERROR',
  UPDATE: 'GALLERY_UPDATE',
  UPDATE_SUCCESS: 'GALLERY_UPDATE_SUCCESS',
  DELETE: 'GALLERY_DELETE',
  DELETE_SUCCESS: 'GALLERY_DELETE_SUCCESS',
  ORDER_GALLERY_PRODUCT: 'ORDER_GALLERY_PRODUCT',
  ORDER_GALLERY_PRODUCT_SUCCESS: 'ORDER_GALLERY_PRODUCT_SUCCESS',
  ADD_RELATED_GALLERY: 'GALLERY_ADD_RELATED_GALLERY',
  ADD_RELATED_GALLERY_SUCCESS: 'GALLERY_ADD_RELATED_GALLERY_SUCCESS',
  REMOVE_RELATED_GALLERY: 'GALLERY_REMOVE_RELATED_GALLERY',
  REMOVE_RELATED_GALLERY_SUCCESS: 'GALLERY_REMOVE_RELATED_GALLERY_SUCCESS',
  list: (authToken, isTailored = false) => ({
    type: actions.LIST,
    authToken,
    isTailored
  }),
  get: (id) => ({
    type: actions.GET,
    id
  }),
  create: (fieldData, authToken) => ({
    type: actions.CREATE,
    fieldData,
    authToken
  }),
  update: (id, fieldData, authToken) => ({
    type: actions.UPDATE,
    id,
    fieldData,
    authToken
  }),
  delete: (id, authToken) => ({
    type: actions.DELETE,
    id,
    authToken
  }),
  orderGalleryProduct: (id, movedProductId, destinationIndex, authToken) => ({
    type: actions.ORDER_GALLERY_PRODUCT,
    id,
    movedProductId,
    destinationIndex,
    authToken
  }),
  addRelatedGallery: (id, relatedGalleryId, authToken) => ({
    type: actions.ADD_RELATED_GALLERY,
    id,
    relatedGalleryId,
    authToken
  }),
  removeRelatedGallery: (id, relatedGalleryId, authToken) => ({
    type: actions.REMOVE_RELATED_GALLERY,
    id,
    relatedGalleryId,
    authToken
  }),
};
export default actions;
