const actions = {
  LIST: 'TAILORED_BRIEF_LIST',
  LIST_SUCCESS: 'TAILORED_BRIEF_LIST_SUCCESS',
  LIST_ERROR: 'TAILORED_BRIEF_LIST_ERROR',
  list: (params, authToken) => ({
    type: actions.LIST,
    params,
    authToken
  }),
 
};
export default actions;
