import actions from "./actions";

export default function reducer(state = {models: [], editModel: undefined, info: undefined}, action) {
  let actionIndex
  let models

  switch (action.type) {
    case actions.MERGE:
      return {
        ...state,
        loading: true
      }
    case actions.MERGE_SUCCESS:
      return {
        models: action.models,
        info: action.info,
        editModel: state.editModel,
        loading: false
      };
    case actions.LIST_SUCCESS:
      return {
        models: action.models,
        info: action.info,
        editModel: state.editModel
      };
    case actions.LIST_ERROR:
      return {
        models: [],
        info: undefined,
        editModel: state.editModel,
        type: action.type,
        error: action.error
      };
    case actions.GET:
      return {
        models: state.models,
        info: state.info,
        editModel: undefined
      };
    case actions.GET_SUCCESS:
      return {
        models: state.models,
        info: action.info,
        editModel: action.editModel
      };
    case actions.DELETE_SUCCESS:
      actionIndex = state.models.findIndex(p => p.id === action.id)
      models = [...state.models]
      if (actionIndex !== -1) {
        models.splice(actionIndex, 1)
      }
      return {
        models,
        info: state.info,
        editModel: undefined
      };
    case actions.GET_ERROR:
      return {
        models: state.models,
        info: state.info,
        editModel: undefined,
        type: action.type,
        error: action.error
      };
    case actions.CREATE_SUCCESS:
    case actions.UPDATE_SUCCESS:
      if (state.editModel) {
        action.editModel.products = [...state.editModel.products]
      }
      return {
        ...state,
        type: undefined,
        error: undefined,
        editModel: action.editModel
      };
    case actions.LIST_HEADSHOTS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        viewingHeadshots: undefined
      };
    case actions.UPDATE_HEADSHOTS_SUCCESS:
      actionIndex = state.models.findIndex(p => p.id === action.viewingModel.id)
      models = [...state.models]
      if (actionIndex > -1) models[actionIndex] = {...models[actionIndex], ...action.viewingModel, headshots: undefined}
      return {
        ...state,
        type: undefined,
        error: undefined,
        viewingHeadshots: action.viewingModel.headshots,
        models
      };
    case actions.LIST_HEADSHOTS_SUCCESS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        viewingHeadshots: action.headshots
      };
    case actions.UPLOAD_HEADSHOTS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        uploading: true,
      }
    case actions.UPLOAD_SUCCESS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        uploading: undefined,
        editModel: {...state.editModel,
          headshotUrl: action.headshots && action.headshots.length > 0 ? action.headshots[0].thumbnailUrl : state.editModel.headshotUrl,
          headshotRotation: action.headshots && action.headshots.length > 0 ? action.headshots[0].rotation : state.editModel.headshotRotation,
          headshots: action.headshots
        }
      };
    case actions.CRUD_ERROR:
      return {
        models: state.models,
        info: state.info,
        editModel: state.editModel,
        type: action.type,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
}
