import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import service from './service';

export function* orderList() {
  yield takeLatest(actions.LIST_ORDER, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.orderList(params, authToken), action)
      yield put({
        type: actions.LIST_ORDER_SUCCESS,
        objects: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST_ORDER,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* list() {
  yield takeLatest(actions.LIST, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.list(params, authToken), action)
      yield put({
        type: actions.LIST_SUCCESS,
        objects: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* get() {
  yield takeLatest(actions.GET, function*(action) {
    try {
      const response = yield call(({id, authToken}) => service.get(id, authToken), action)
      yield put({
        type: actions.GET_SUCCESS,
        editObject: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        cause: actions.GET,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* update() {
  yield takeEvery(actions.UPDATE, function*(action) {
    try {
      const response = yield call(({id, fieldData, authToken}) => service.update(id, fieldData, authToken), action)

      yield put({
        type: actions.UPDATE_SUCCESS,
        editObject: response.data.data
      })
    }
    catch (error){
      yield put({
        cause: actions.UPDATE,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* create() {
  yield takeEvery(actions.CREATE, function*(action) {
    try {
      const response = yield call(({fieldData, authToken}) => service.create(fieldData, authToken), action)

      yield put({
        type: actions.CREATE_SUCCESS,
        editObject: response.data.data
      })
    }
    catch (error){
      yield put({
        cause: actions.CREATE,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* removeOrder() {
  yield takeEvery(actions.REMOVE_ORDER, function*(action) {
    try {
      yield call(({orderId, authToken}) => service.removeOrder(orderId, authToken), action)

      yield put({
        type: actions.RELOAD_ORDERS,
        cause: actions.REMOVE_ORDER
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(orderList),
    fork(list),
    fork(get),
    fork(update),
    fork(create),
    fork(removeOrder)
  ]);
}
