import HeroApiHelper from '../../helpers/axios'

class Service extends HeroApiHelper {
  list(params, authToken) {
    return super.get(`order`, params, authToken)
  }

  get(id, authToken) {
    return super.get(`order/${id}`, {}, authToken)
  }

  update(id, fieldData, authToken) {
    return super.put(`/order/${id}`, fieldData, authToken)
  }

  create(fieldData, authToken) {
    const {userId, status, createdAt, poNumber, licenceOwner, firstName, lastName, company, orderType, totalUSD, discountUSD, purchaserName, isInvoice, comments } = fieldData
    return super.post('/order', {userId, status, createdAt, poNumber, licenceOwner, firstName, lastName, company, orderType, totalUSD, discountUSD, purchaserName, isInvoice, comments }, authToken)
  }

  listUsers(authToken) {
    return super.get(`admin/user/simple`, {}, authToken)
  }
  listProducts(authToken) {
    return super.get(`admin/product`, {}, authToken)
  }
  listPrices() {
    return super.get('price/')
  }

  addProduct(fieldData, authToken) {
    const { orderId, fileName, priceId, pricePaid } = fieldData
    return super.post('order/addProduct', { orderId, fileName, priceId, pricePaid }, authToken );
  }

  removeProduct(productOrderPriceId, authToken) {
    return super.delete(`/order/removeProduct/${productOrderPriceId}`, authToken)
  }
}

export default new Service()