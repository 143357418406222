import Auth from './auth/reducer';
import App from './app/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import * as gallery from './gallery/reducer';
import Coupons from './coupon/reducer';
import UserCredits from './userCredit/reducer';
import Orders from './order/reducer';
import Users from './user/reducer';
import Models from './model/reducer';
import Properties from './property/reducer';
import Photoshoots from './photoshoot/reducer';
import Products from './product/reducer';
import RoyaltyImports from './royaltyImport/reducer';
import RoyaltyReports from './royaltyReport/reducer';
import TailoredBrief from './tailoredBrief/reducer';

export default {
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Galleries: gallery.listReducer,
  Gallery: gallery.reducer,
  Coupons,
  UserCredits,
  Orders,
  Users,
  Models,
  Properties,
  Photoshoots,
  Products,
  RoyaltyImports,
  RoyaltyReports,
  TailoredBrief,
};
