const actions = {
  LIST: 'PRODUCT_LIST',
  LIST_SUCCESS: 'PRODUCT_LIST_SUCCESS',
  LIST_ERROR: 'PRODUCT_LIST_ERROR',
  GET: 'PRODUCT_GET',
  GET_SUCCESS: 'PRODUCT_GET_SUCCESS',
  GET_ERROR: 'PRODUCT_GET_ERROR',
  UPDATE: 'PRODUCT_UPDATE',
  UPDATE_SUCCESS: 'PRODUCT_UPDATE_SUCCESS',
  DELETE: 'PRODUCT_DELETE',
  CRUD_ERROR: 'PRODUCT_CRUD_ERROR',
  RANK: 'PRODUCT_RANK_PRODUCTS',
  RELOAD: 'PRODUCT_RELOAD',
  list: (params, authToken) => ({
    type: actions.LIST,
    params,
    authToken
  }),
  get: (id, authToken) => ({
    type: actions.GET,
    id,
    authToken
  }),
  update: (id, fieldData, authToken) => ({
    type: actions.UPDATE,
    id,
    fieldData,
    authToken
  }),
  delete: (id, authToken) => ({
    type: actions.DELETE,
    id,
    authToken
  }),
  rank: (photoshootId, productIds, rank, authToken) => ({
    type: actions.RANK,
    photoshootId,
    productIds,
    rank,
    authToken
  })
};
export default actions;
