import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import service from './service';

export function* list() {
  yield takeLatest(actions.LIST, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.list(params, authToken), action)
      yield put({
        type: actions.LIST_SUCCESS,
        properties: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* get() {
  yield takeEvery(actions.GET, function*(action) {
    try {
      const response = yield call(({id, params, authToken}) => service.get(id, params, authToken), action)
      yield put({
        type: actions.GET_SUCCESS,
        editProperty: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.GET_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* create() {
  yield takeEvery(actions.CREATE, function*(action) {
    try {
      const response = yield call(({fieldData, authToken}) => service.create(fieldData, authToken), action)
      yield put({
        type: actions.CREATE_SUCCESS,
        editProperty: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* update() {
  yield takeEvery(actions.UPDATE, function*(action) {
    try {
      const response = yield call(({id, fieldData, authToken}) => service.update(id, fieldData, authToken), action)
      yield put({
        type: actions.UPDATE_SUCCESS,
        editProperty: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* deleteProperty() {
  yield takeEvery(actions.DELETE, function*(action) {
    try {
      const response = yield call(({id, authToken}) => service.delete(id, authToken), action)
      yield put({
        type: actions.DELETE_SUCCESS,
        id: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* mergeProperty() {
  yield takeEvery(actions.MERGE, function*(action) {
    try {
      const response = yield call(({id, duplicatePropertyId, authToken}) => service.merge(id, duplicatePropertyId, authToken), action)
      yield put({
        type: actions.MERGE_SUCCESS,
        properties: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(list),
    fork(get),
    fork(create),
    fork(update),
    fork(deleteProperty),
    fork(mergeProperty)
  ]);
}
