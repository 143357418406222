import actions from "./actions";

export default function reducer(state = {bundleUsers: [], prices: [], users: [], userCredits: [], editUserCredit: undefined, info: undefined}, action) {
  let actionIndex
  let userCredits

  switch (action.type) {
    case actions.LIST_BUNDLE_USERS_SUCCESS:
      return {
        bundleUsers: action.bundleUsers,
        prices: [],
        users: [],
        userCredits: [],
        editUserCredit: undefined,
        info: action.info
      };
    case actions.LIST_BUNDLE_USERS_ERROR:
      return {
        ...state,
        bundleUsers: [],
        type: action.type,
        error: action.error
      };
    case actions.LIST_PRICES_SUCCESS:
      return {
        ...state,
        prices: action.prices
      };
    case actions.LIST_PRICES_ERROR:
      return {
        ...state,
        prices: [],
        type: action.type,
        error: action.error
      };
    case actions.LIST_USERS:
      return {
        bundleUsers: state.bundleUsers,
        prices: state.prices,
        users: state.users,
        userCredits: [],
        info: undefined,
        editUserCredit: state.editUserCredit
      };
    case actions.LIST_USERS_SUCCESS:
      return {
        bundleUsers: state.bundleUsers,
        prices: state.prices,
        users: action.users,
        userCredits: state.userCredits,
        info: state.info,
        editUserCredit: state.editUserCredit
      };
    case actions.LIST_USERS_ERROR:
      return {
        bundleUsers: state.bundleUsers,
        prices: state.prices,
        users: [],
        userCredits: state.userCredits,
        info: state.info,
        editUserCredit: state.editUserCredit,
        type: action.type,
        error: action.error
      };
    case actions.LIST_SUCCESS:
      return {
        bundleUsers: state.bundleUsers,
        prices: state.prices,
        users: state.users,
        userCredits: action.userCredits,
        info: action.info,
        editUserCredit: state.editUserCredit
      };
    case actions.LIST_ERROR:
      return {
        bundleUsers: state.bundleUsers,
        prices: state.prices,
        users: state.users,
        userCredits: [],
        info: undefined,
        editUserCredit: state.editUserCredit,
        type: action.type,
        error: action.error
      };
    case actions.GET:
      return {
        bundleUsers: state.bundleUsers,
        prices: state.prices,
        users: state.users,
        userCredits: state.userCredits,
        info: state.info,
        editUserCredit: undefined
      };
    case actions.GET_SUCCESS:
      actionIndex = state.userCredits.findIndex(p => p.id === action.editUserCredit.id)
      userCredits = [...state.userCredits]
      userCredits[actionIndex !== -1 ? actionIndex : userCredits.length] = action.editUserCredit
      return {
        bundleUsers: state.bundleUsers,
        prices: state.prices,
        users: [],
        userCredits,
        info: state.info,
        editUserCredit: action.editUserCredit
      };
    case actions.GET_ERROR:
      return {
        bundleUsers: state.bundleUsers,
        prices: state.prices,
        users: state.users,
        userCredits: state.userCredits,
        info: state.info,
        editUserCredit: undefined,
        type: action.type,
        error: action.error
      };
    
    case actions.UPDATE_SUCCESS:
      actionIndex = state.userCredits.findIndex(p => p.id === action.editUserCredit.id)
      action.editUserCredit.history = [action.updateRecord, ...state.editUserCredit.history]
      userCredits = [...state.userCredits]
      userCredits[actionIndex !== -1 ? actionIndex : userCredits.length] = action.editUserCredit
      return {
        bundleUsers: state.bundleUsers,
        prices: state.prices,
        users: [],
        userCredits,
        info: state.info,
        editUserCredit: action.editUserCredit
      };
    case actions.CRUD_ERROR:
      return {
        bundleUsers: state.bundleUsers,
        prices: state.prices,
        users: state.users,
        userCredits: state.userCredits,
        info: state.info,
        editUserCredit: state.editUserCredit,
        type: action.type,
        error: action.error
      };
    default:
      return state;
  }
}
