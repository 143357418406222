/**
 * src/modules/_core/service.js
 */

import axios from 'axios'

import { heroConfig } from '../../settings'

const compileAuthorizationHeaders = (token) => {
  const headers = {}

  if (token) {
    headers['authorization'] = `jwt ${token}`
  }

  return headers
}

class HeroApiHelper {
  constructor(host, timeout) {
    this._instance = axios.create({
      baseURL: host || heroConfig.apiHost,
      timeout: timeout || heroConfig.timeout
    })
  }

  get(endpoint, query, token) {
    return this._instance.get(endpoint, {
      params: query,
      headers: compileAuthorizationHeaders(token)
    })
  }

  getBlob(endpoint, query, token) {
    return this._instance.get(endpoint, {
      params: query,
      headers: compileAuthorizationHeaders(token),
      responseType: 'blob'
    })
  }

  getSlowBlob(endpoint, query, token) {
    return this._instance.get(endpoint, {
      params: query,
      headers: compileAuthorizationHeaders(token),
      responseType: 'blob',
      timeout: 240000
    })
  }


  post(endpoint, data, token) {
    return this._instance.post(endpoint, data, {
      headers: compileAuthorizationHeaders(token)
    })
  }

  put(endpoint, data, token) {
    return this._instance.put(endpoint, data, {
      headers: compileAuthorizationHeaders(token)
    })
  }

  patch(endpoint, data, token) {
    return this._instance.patch(endpoint, data, {
      headers: compileAuthorizationHeaders(token)
    })
  }

  delete(endpoint, token) {
    return this._instance.delete(endpoint, {
      headers: compileAuthorizationHeaders(token)
    })
  }
}

export default HeroApiHelper;