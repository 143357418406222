import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import service from './service';

export function* listBundleUsers() {
  yield takeLatest(actions.LIST_BUNDLE_USERS, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.listBundleUsers(params, authToken), action)
      yield put({
        type: actions.LIST_BUNDLE_USERS_SUCCESS,
        bundleUsers: response.data.data,
        info: response.data.info || {total: response.data.data.length}
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_BUNDLE_USERS_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listPrices() {
  yield takeLatest(actions.LIST_PRICES, function*(action) {
    try {
      const response = yield call(() => service.listPrices(), action)
      yield put({
        type: actions.LIST_PRICES_SUCCESS,
        prices: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_PRICES_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listUsers() {
  yield takeLatest(actions.LIST_USERS, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.listUsers(params, authToken), action)
      yield put({
        type: actions.LIST_USERS_SUCCESS,
        users: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_USERS_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* list() {
  yield takeLatest(actions.LIST, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.list(params, authToken), action)
      yield put({
        type: actions.LIST_SUCCESS,
        userCredits: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* get() {
  yield takeEvery(actions.GET, function*(action) {
    try {
      const response = yield call(({id, authToken}) => service.get(id, authToken), action)
      yield put({
        type: actions.GET_SUCCESS,
        editUserCredit: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.GET_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* create() {
  yield takeEvery(actions.CREATE, function*(action) {
    try {
      const response = yield call(({fieldData, authToken}) => service.create(fieldData, authToken), action)
      yield put({
        type: actions.CREATE_SUCCESS,
        id: response.data.info
      })
     // yield put(push(`/dashboard/usercredit/${response.data.info}`))
     //yield put(push(`/dashboard/user/${response.data.info}`))
     //well, this is super shitty.  But I'm in a rush, and don't know how to reload the usercredits on the current pages
     // (this can be called from both usercredits/user and user/edit.  This works, but just updatin the list with a toast would be better)
     window.location.reload()
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* update() {
  yield takeEvery(actions.UPDATE, function*(action) {
    try {
      const response = yield call(({id, fieldData, authToken}) => service.update(id, fieldData, authToken), action)
      yield put({
        type: actions.UPDATE_SUCCESS,
        editUserCredit: response.data.data[0],
        updateRecord: response.data.data[1]
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(listBundleUsers),
    fork(listPrices),
    fork(listUsers),
    fork(list),
    fork(get),
    fork(create),
    fork(update)
  ]);
}
