import { all, takeLatest, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import service from './service';

export function* list() {
  yield takeLatest(actions.LIST, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.list(params, authToken), action)
      yield put({
        type: actions.LIST_SUCCESS,
        properties: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}



export default function* rootSaga() {
  yield all([
    fork(list),
  ]);
}
