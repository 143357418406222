import HeroApiHelper from '../../helpers/axios'

class Service extends HeroApiHelper {
  listModels(search, authToken) {
    return super.get(`admin/model/simple`, search, authToken)
  }
  
  listProperties(search, authToken) {
    return super.get(`admin/property/simple`, search, authToken)
  }

  listPhotographers(authToken) {
    return super.get(`admin/photoshoot/photographer`, {}, authToken)
  }

  listArtDirectors(authToken) {
    return super.get(`admin/photoshoot/artdirector`, {}, authToken)
  }

  listVerticals(authToken) {
    return super.get(`admin/photoshoot/vertical`, {}, authToken)
  }

  list(params, authToken) {
    return super.get(`admin/photoshoot`, params, authToken)
  }

  get(id, productParams, authToken) {
    return super.get(`admin/photoshoot/${id}`, productParams, authToken)
  }

  create(fieldData, authToken) {
    const {id, title, shootDate, photographers, artDirectorId, verticalCategory, photographerRoyaltyRate, models, properties, isActive, country} = fieldData
    return super.post('/admin/photoshoot', {id, title, shootDate, photographers, artDirectorId, verticalCategory, photographerRoyaltyRate, models, properties, isActive, country}, authToken)
  }

  update(currentId, fieldData, authToken) {
    const {id, title, shootDate, photographers, artDirectorId, verticalCategory, photographerRoyaltyRate, models, properties, isActive, country} = fieldData
    return super.patch(`/admin/photoshoot/${currentId}`, {id, title, shootDate, photographers, artDirectorId, verticalCategory, photographerRoyaltyRate, models, properties, isActive, country}, authToken)
  }

  delete(id, authToken) {
    return super.delete(`/admin/photoshoot/${id}`, authToken)
  }

  exportFormat(photoshootIds, format, authToken) {
    return super.getBlob(`/admin/photoshoot/export/${format.toLowerCase()}`, {photoshootIds}, authToken)
  }

  exportTemplate(id, authToken) {
    return super.getBlob(`/admin/photoshoot/export/template/${id}`, {}, authToken)
  }

  importMetadata(id, file, authToken) {
    const data = new FormData()
    data.append('file', file, file.name)
    return super.post(`/admin/photoshoot/${id}/import`, data, authToken)
  }

  setFeature(id, productId, authToken){
    return super.put(`/admin/photoshoot/${id}/product/${productId}`, {}, authToken)
  }

  rankProducts(photoshootId, productIds, rank, authToken){
    return super.put(`/admin/product/rank`, {photoshootId, productIds, rank}, authToken)
  }
  
  removeProduct(id, productId, authToken){
    return super.delete(`/admin/product/${productId}`, authToken)
  }
}

export default new Service()