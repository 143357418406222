import HeroApiHelper from '../../helpers/axios'

class Service extends HeroApiHelper {
  listBundleUsers(params, authToken) {
    return super.get('admin/user/bundleusers', params, authToken)
  }

  listUsers(params, authToken) {
    return super.get('admin/user/simple', params, authToken)
  }

  listPrices() {
    return super.get('price/')
  }

  list(params, authToken) {
    return super.get(`admin/usercredit`, params, authToken)
  }

  get(userCreditId, authToken) {
    return super.get(`admin/usercredit/${userCreditId}`, {}, authToken)
  }

  create(fieldData, authToken) {
    const {userId, bundleId, remaining, pricePaid} = fieldData
    return super.post('/admin/usercredit', {userId, bundleId, remaining, pricePaid}, authToken)
  }

  update(userCreditId, fieldData, authToken) {
    const {expiredAt} = fieldData
    return super.patch(`/admin/usercredit/${userCreditId}`, {expiredAt}, authToken)
  }
}

export default new Service()