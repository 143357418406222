const actions = {
  LIST: 'PROPERTY_LIST',
  LIST_SUCCESS: 'PROPERTY_LIST_SUCCESS',
  LIST_ERROR: 'PROPERTY_LIST_ERROR',
  GET: 'PROPERTY_GET',
  GET_SUCCESS: 'PROPERTY_GET_SUCCESS',
  GET_ERROR: 'PROPERTY_GET_ERROR',
  CREATE: 'PROPERTY_CREATE',
  CREATE_SUCCESS: 'PROPERTY_CREATE_SUCCESS',
  UPDATE: 'PROPERTY_UPDATE',
  UPDATE_SUCCESS: 'PROPERTY_UPDATE_SUCCESS',
  DELETE: 'PROPERTY_DELETE',
  DELETE_SUCCESS: 'PROPERTY_DELETE_SUCCESS',
  MERGE: 'PROPERTY_MERGE',
  MERGE_SUCCESS: 'PROPERTY_MERGE_SUCCESS',
  CRUD_ERROR: 'PROPERTY_CRUD_ERROR',
  list: (params, authToken) => ({
    type: actions.LIST,
    params,
    authToken
  }),
  get: (id, params, authToken) => ({
    type: actions.GET,
    id,
    params,
    authToken
  }),
  create: (fieldData, authToken) => ({
    type: actions.CREATE,
    fieldData,
    authToken
  }),
  update: (id, fieldData, authToken) => ({
    type: actions.UPDATE,
    id,
    fieldData,
    authToken
  }),
  delete: (id, authToken) => ({
    type: actions.DELETE,
    id,
    authToken
  }),
  merge: (id, duplicatePropertyId, authToken) => ({
    type: actions.MERGE,
    id,
    duplicatePropertyId,
    authToken
  })
};
export default actions;
