import { all, takeLatest, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import service from './service';

export function* list() {
  yield takeLatest(actions.LIST, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.list(params, authToken), action)
      yield put({
        type: actions.LIST_SUCCESS,
        products: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* get() {
  yield takeEvery(actions.GET, function*(action) {
    try {
      const response = yield call(({id, authToken}) => service.get(id, authToken), action)
      yield put({
        type: actions.GET_SUCCESS,
        editProduct: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.GET_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* update() {
  yield takeEvery(actions.UPDATE, function*(action) {
    try {
      const response = yield call(({id, fieldData, authToken}) => service.update(id, fieldData, authToken), action)
      yield put({
        type: actions.UPDATE_SUCCESS,
        editProduct: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* deleteProduct() {
  yield takeEvery(actions.DELETE, function*(action) {
    try {
      yield call(({id, authToken}) => service.delete(id, authToken), action)

      yield put({
        type: actions.RELOAD,
        cause: actions.DELETE
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* rank() {
  yield takeEvery(actions.RANK, function*(action) {
    try {
      yield call(({photoshootId, productIds, rank, authToken}) => service.rank(photoshootId, productIds, rank, authToken), action)

      yield put({
        type: actions.RELOAD,
        cause: actions.RANK
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(list),
    fork(get),
    fork(update),
    fork(deleteProduct),
    fork(rank)
  ]);
}
