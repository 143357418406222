const actions = {
  LIST_DISTRIBUTORS: 'LIST_DISTRIBUTORS',
  LIST_DISTRIBUTORS_SUCCESS: 'LIST_DISTRIBUTORS_SUCCESS',
  LIST_CURRENCIES: 'LIST_CURRENCIES',
  LIST_CURRENCIES_SUCCESS: 'LIST_CURRENCIES_SUCCESS',
  LIST_ROYALTY_PERIODS: 'LIST_ROYALTY_PERIODS',
  LIST_ROYALTY_PERIODS_SUCCESS: 'LIST_ROYALTY_PERIODS_SUCCESS',
  LIST: 'ROYALTYIMPORT_LIST',
  LIST_SUCCESS: 'ROYALTYIMPORT_LIST_SUCCESS',
  GET: 'ROYALTYIMPORT_GET',
  GET_SUCCESS: 'ROYALTYIMPORT_GET_SUCCESS',
  CREATE: 'ROYALTYIMPORT_CREATE',
  CREATE_SUCCESS: 'ROYALTYIMPORT_CREATE_SUCCESS',
  UPDATE: 'ROYALTYIMPORT_UPDATE',
  UPDATE_SUCCESS: 'ROYALTYIMPORT_UPDATE_SUCCESS',
  DELETE: 'ROYALTYIMPORT_DELETE',
  DELETE_SUCCESS: 'ROYALTYIMPORT_DELETE_SUCCESS',
  EXPORT_TEMPLATE: 'ROYALTYIMPORT_EXPORT_TEMPLATE',
  EXPORT_SUCCESS: 'ROYALTYIMPORT_EXPORT_SUCCESS',
  CRUD_ERROR: 'ROYALTYIMPORT_CRUD_ERROR',
  listDistributors: (authToken) => ({
    type: actions.LIST_DISTRIBUTORS,
    authToken,
  }),
  listCurrencies: (authToken) => ({
    type: actions.LIST_CURRENCIES,
    authToken,
  }),
  listRoyaltyPeriods: (distributorId, authToken) => ({
    type: actions.LIST_ROYALTY_PERIODS,
    distributorId,
    authToken,
  }),
  list: (params, authToken) => ({
    type: actions.LIST,
    params,
    authToken
  }),
  get: (id, lineParams, authToken) => ({
    type: actions.GET,
    id,
    lineParams,
    authToken
  }),
  create: (fieldData, file, authToken) => ({
    type: actions.CREATE,
    fieldData,
    file,
    authToken
  }),
  update: (id, file, authToken) => ({
    type: actions.UPDATE,
    id,
    file,
    authToken
  }),
  delete: (id, authToken) => ({
    type: actions.DELETE,
    id,
    authToken
  }),
  exportTemplate: (authToken) => ({
    type: actions.EXPORT_TEMPLATE,
    authToken
  })
};
export default actions;
