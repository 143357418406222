import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import service from './service';

export function* listUsers() {
  yield takeLatest(actions.LIST_USERS, function*(action) {
    
    try {
      if (!action.search) {
        yield put({
          type: actions.LIST_USERS_SUCCESS,
          userResults: [],
          info: undefined
        })
      }
      else {
        const response = yield call(({search, authToken}) => service.listUsers(search, authToken), action)
        yield put({
          type: actions.LIST_USERS_SUCCESS,
          userResults: response.data.data,
          info: response.data.info
        })
      }
    }
    catch (error){
      yield put({
        type: actions.LIST_USERS_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}


export function* list() {
  yield takeLatest(actions.LIST, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.list(params, authToken), action)
      yield put({
        type: actions.LIST_SUCCESS,
        coupons: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* get() {
  yield takeEvery(actions.GET, function*(action) {
    try {
      const response = yield call(({id, authToken}) => service.get(id, authToken), action)
      yield put({
        type: actions.GET_SUCCESS,
        editCoupon: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.GET_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* create() {
  yield takeEvery(actions.CREATE, function*(action) {
    try {
      const response = yield call(({fieldData, authToken}) => service.create(fieldData, authToken), action)
      yield put({
        type: actions.CREATE_SUCCESS,
        editCoupon: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* update() {
  yield takeEvery(actions.UPDATE, function*(action) {
    try {
      const response = yield call(({id, fieldData, authToken}) => service.update(id, fieldData, authToken), action)
      yield put({
        type: actions.UPDATE_SUCCESS,
        editCoupon: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* deleteCoupon() {
  yield takeEvery(actions.DELETE, function*(action) {
    try {
      const response = yield call(({id, authToken}) => service.delete(id, authToken), action)
      yield put({
        type: actions.DELETE_SUCCESS,
        id: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(listUsers),
    fork(list),
    fork(get),
    fork(create),
    fork(update),
    fork(deleteCoupon)
  ]);
}
