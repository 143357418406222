
let options = [
  {
    key: 'galleries',
    label: 'sidebar.galleries',
    leftIcon: 'fas fa-images',
  },
  {
    key: 'tailoredGalleries',
    label: 'sidebar.tailoredGalleries',
    leftIcon: 'ion-grid',
  },
  {
    key: 'tailoredBriefs',
    label: 'sidebar.tailoredBriefs',
    leftIcon: 'fas fa-clipboard',
  },
  {
    key: 'coupons',
    label: 'sidebar.coupons',
    leftIcon: 'fas fa-tag',
  },
  {
    key: 'usercredits',
    label: 'sidebar.userCredits',
    leftIcon: 'fas fa-layer-group',
  },
  {
    key: 'orders',
    label: 'sidebar.orders',
    leftIcon: 'fas fa-file-invoice-dollar',
  },
  {
    key: 'users',
    label: 'sidebar.users',
    leftIcon: 'fas fa-user-edit',
  },
  {
    key: 'models',
    label: 'sidebar.models',
    leftIcon: 'fas fa-portrait',
  },
  {
    key: 'properties',
    label: 'sidebar.properties',
    leftIcon: 'fas fa-map-marker-alt',
  },
  {
    key: 'photoshoots',
    label: 'sidebar.photoshoots',
    leftIcon: 'fas fa-camera',
  },
  {
    key: 'products',
    label: 'sidebar.products',
    leftIcon: 'fas fa-images',
  },
  {
    key: 'royaltyimports',
    label: 'sidebar.royaltyImports',
    leftIcon: 'fas fa-download',
    roleGroup: ['superAdmin']
  },
  {
    key: 'royaltyreports',
    label: 'sidebar.royaltyReports',
    leftIcon: 'fas fa-chart-line',
    roleGroup: ['superAdmin', 'artDirectorAdmin']
  }
];

export default options;
