import HeroApiHelper from '../../helpers/axios'

class Service extends HeroApiHelper {
  listUsers(search, authToken) {
    console.log('search', search)
    return super.get(`admin/user/simple`, search, authToken)
  }

  list(params, authToken) {
    return super.get(`admin/coupon`, params, authToken)
  }

  get(id, authToken) {
    return super.get(`admin/coupon/${id}`, {}, authToken)
  }

  create(fieldData, authToken) {
    const {code, name, couponType, percentDiscount, flatDiscount, remaining, usesPerCustomer, expiredAt, notes, users} = fieldData
    return super.post('/admin/coupon', {code, name, couponType, percentDiscount, flatDiscount, remaining, usesPerCustomer, expiredAt, notes, users}, authToken)
  }

  update(id, fieldData, authToken) {
    const {code, name, couponType, percentDiscount, flatDiscount, remaining, usesPerCustomer, expiredAt, notes, users} = fieldData
    return super.patch(`/admin/coupon/${id}`, {code, name, couponType, percentDiscount, flatDiscount, remaining, usesPerCustomer, expiredAt, notes, users}, authToken)
  }

  delete(id, authToken) {
    return super.delete(`/admin/coupon/${id}`, authToken)
  }
}

export default new Service()