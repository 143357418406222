import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import * as FileDownload from 'js-file-download'

import actions from './actions';
import service from './service';

import fileReader from '../../helpers/fileReader'

export function* listModels() {
  yield takeLatest(actions.LIST_MODELS, function*(action) {
    try {
      if (!action.search) {
        yield put({
          type: actions.LIST_MODELS_SUCCESS,
          modelResults: [],
          info: undefined
        })
      }
      else {
        const response = yield call(({search, authToken}) => service.listModels({search}, authToken), action)
        yield put({
          type: actions.LIST_MODELS_SUCCESS,
          modelResults: response.data.data,
          info: response.data.info
        })
      }
    }
    catch (error){
      yield put({
        type: actions.LIST_MODELS_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listProperties() {
  yield takeLatest(actions.LIST_PROPERTIES, function*(action) {
    try {
      if (!action.search) {
        yield put({
          type: actions.LIST_PROPERTIES_SUCCESS,
          propertyResults: [],
          info: undefined
        })
      }
      else {
        const response = yield call(({search, authToken}) => service.listProperties({search}, authToken), action)
        yield put({
          type: actions.LIST_PROPERTIES_SUCCESS,
          propertyResults: response.data.data,
          info: response.data.info
        })
      }
    }
    catch (error){
      yield put({
        type: actions.LIST_PROPERTIES_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listPhotographers() {
  yield takeLatest(actions.LIST_PHOTOGRAPHERS, function*(action) {
    try {
      const response = yield call(({authToken}) => service.listPhotographers(authToken), action)
      yield put({
        type: actions.LIST_PHOTOGRAPHERS_SUCCESS,
        photographers: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_PHOTOGRAPHERS_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listArtDirectors() {
  yield takeLatest(actions.LIST_ART_DIRECTORS, function*(action) {
    try {
      const response = yield call(({authToken}) => service.listArtDirectors(authToken), action)
      yield put({
        type: actions.LIST_ART_DIRECTORS_SUCCESS,
        artDirectors: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_ART_DIRECTORS_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listVerticals() {
  yield takeLatest(actions.LIST_VERTICALS, function*(action) {
    try {
      const response = yield call(({authToken}) => service.listVerticals(authToken), action)
      yield put({
        type: actions.LIST_VERTICALS_SUCCESS,
        verticals: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_VERTICALS_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* list() {
  yield takeLatest(actions.LIST, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.list(params, authToken), action)
      yield put({
        type: actions.LIST_SUCCESS,
        photoshoots: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* get() {
  yield takeEvery(actions.GET, function*(action) {
    try {
      const response = yield call(({id, productParams, authToken}) => service.get(id, productParams, authToken), action)
      yield put({
        type: actions.GET_SUCCESS,
        editPhotoshoot: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.GET_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* create() {
  yield takeEvery(actions.CREATE, function*(action) {
    try {
      const response = yield call(({fieldData, authToken}) => service.create(fieldData, authToken), action)
      yield put({
        type: actions.CREATE_SUCCESS,
        editPhotoshoot: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* update() {
  yield takeEvery(actions.UPDATE, function*(action) {
    try {
      const response = yield call(({id, fieldData, authToken}) => service.update(id, fieldData, authToken), action)
      yield put({
        type: actions.UPDATE_SUCCESS,
        editPhotoshoot: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* deletePhotoshoot() {
  yield takeEvery(actions.DELETE, function*(action) {
    try {
      const response = yield call(({id, authToken}) => service.delete(id, authToken), action)
      yield put({
        type: actions.DELETE_SUCCESS,
        id: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* exportFormat() {
  yield takeEvery(actions.EXPORT_FORMAT, function*(action) {
    try {
      const response = yield call(({photoshootIds, format, authToken}) => service.exportFormat(photoshootIds, format, authToken), action)
      FileDownload(response.data, response.headers['x-suggested-filename']);
      yield put({
        type: actions.EXPORT_SUCCESS
      })
    }
    catch (error){
      if (error.message) {
        yield put({
          cause: actions.EXPORT_FORMAT,
          type: actions.CRUD_ERROR,
          error: { message: error.message }
        })
        return;
      }
      const errorResponse = yield fileReader(error.response.data)
      const responseData = JSON.parse(errorResponse)
      yield put({
        cause: actions.EXPORT_FORMAT,
        type: actions.CRUD_ERROR,
        error: { message: (responseData && responseData.message) || error.message }
      })
    }
  });
}

export function* exportTemplate() {
  yield takeEvery(actions.EXPORT_TEMPLATE, function*(action) {
    try {
      const response = yield call(({id, authToken}) => service.exportTemplate(id, authToken), action)
      FileDownload(response.data, `${action.id}_HeroPhotoshootTemplate.xlsx`);
      yield put({
        type: actions.EXPORT_SUCCESS,
        info: `${action.id}_HeroPhotoshootTemplate.xlsx`
      })
    }
    catch (error){
      if (error.message) {
        yield put({
          cause: actions.DOWNLOAD_HEADSHOT,
          type: actions.CRUD_ERROR,
          error: { message: error.message }
        })
        return;
      }
      const errorResponse = yield fileReader(error.response.data)
      const responseData = JSON.parse(errorResponse)
      yield put({
        cause: actions.EXPORT_TEMPLATE,
        type: actions.CRUD_ERROR,
        error: { message: (responseData && responseData.message) || error.message }
      })
    }
  });
}

export function* importMetadata() {
  yield takeEvery(actions.IMPORT, function*(action) {
    try {
      // import
      const importResponse = yield call(({id, file, authToken}) => service.importMetadata(id, file, authToken), action)
      yield put({
        type: actions.IMPORT_SUCCESS,
        info: importResponse.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* setFeature() {
  yield takeEvery(actions.SET_FEATURE, function*(action) {
    try {
      const response = yield call(({id, productId, authToken}) => service.setFeature(id, productId, authToken), action)
      yield put({
        type: actions.SET_FEATURE_SUCCESS,
        unfeaturedProducts: response.data.data,
        featuredProductId: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* rankProducts() {
  yield takeEvery(actions.RANK_PRODUCTS, function*(action) {
    try {
      // rank
      yield call(({photoshootId, productIds, rank, authToken}) => service.rankProducts(photoshootId, productIds, rank, authToken), action)

      yield put({
        type: actions.RELOAD,
        cause: actions.RANK_PRODUCTS
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* removeProduct() {
  yield takeEvery(actions.REMOVE_PRODUCT, function*(action) {
    try {
      // remove
      yield call(({id, productId, authToken}) => service.removeProduct(id, productId, authToken), action)

      yield put({
        type: actions.RELOAD,
        cause: actions.REMOVE_PRODUCT
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(listModels),
    fork(listProperties),
    fork(listPhotographers),
    fork(listArtDirectors),
    fork(listVerticals),
    fork(list),
    fork(get),
    fork(create),
    fork(update),
    fork(deletePhotoshoot),
    fork(exportFormat),
    fork(exportTemplate),
    fork(importMetadata),
    fork(setFeature),
    fork(rankProducts),
    fork(removeProduct)
  ]);
}
