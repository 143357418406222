const actions = {
  LIST_PHOTOSHOOTS: 'ROYALTY_REPORT_LIST_PHOTOSHOOTS',
  LIST_PHOTOSHOOTS_SUCCESS: 'ROYALTY_REPORT_LIST_PHOTOSHOOTS_SUCCESS',
  LIST_PHOTOGRAPHERS: 'ROYALTY_REPORT_LIST_PHOTOGRAPHERS',
  LIST_PHOTOGRAPHERS_SUCCESS: 'ROYALTY_REPORT_LIST_PHOTOGRAPHERS_SUCCESS',
  LIST_DISTRIBUTORS: 'ROYALTY_REPORT_LIST_DISTRIBUTORS',
  LIST_DISTRIBUTORS_SUCCESS: 'ROYALTY_REPORT_LIST_DISTRIBUTORS_SUCCESS',
  LIST_ART_DIRECTORS: 'ROYALTY_REPORT_LIST_ART_DIRECTORS',
  LIST_ART_DIRECTORS_SUCCESS: 'ROYALTY_REPORT_LIST_ART_DIRECTORS_SUCCESS',
  LIST_VERTICALS: 'ROYALTY_REPORT_LIST_VERTICALS',
  LIST_VERTICALS_SUCCESS: 'ROYALTY_REPORT_LIST_VERTICALS_SUCCESS',
  LIST_USERS: 'ROYALTY_REPORT_LIST_USERS',
  LIST_USERS_SUCCESS: 'ROYALTY_REPORT_LIST_USERS_SUCCESS',
  LIST: 'ROYALTY_REPORT_LIST',
  LIST_SUCCESS: 'ROYALTY_REPORT_LIST_SUCCESS',
  LIST_BY_PHOTOSHOOT: 'ROYALTY_REPORT_LIST_BY_PHOTOSHOOT',
  LIST_BY_PHOTOSHOOT_SUCCESS: 'ROYALTY_REPORT_LIST_BY_PHOTOSHOOT_SUCCESS',
  LIST_BY_MONTH: 'ROYALTY_REPORT_LIST_BY_MONTH',
  LIST_BY_MONTH_SUCCESS: 'ROYALTY_REPORT_LIST_BY_MONTH_SUCCESS',
  LIST_BY_KEYWORD: 'ROYALTY_REPORT_LIST_BY_KEYWORD',
  LIST_BY_KEYWORD_SUCCESS: 'ROYALTY_REPORT_LIST_BY_KEYWORD_SUCCESS',
  GET: 'ROYALTY_REPORT_GET',
  GET_SUCCESS: 'ROYALTY_REPORT_GET_SUCCESS',
  CRUD_ERROR: 'ROYALTY_REPORT_CRUD_ERROR',
  EXPORT_GENERIC: 'ROYALTY_REPORT_EXPORT_GENERIC',
  EXPORT_VISUAL: 'ROYALTY_REPORT_EXPORT_VISUAL',
  EXPORT_PRODUCT: 'ROYALTY_REPORT_EXPORT_PRODUCT',
  EXPORT_PHOTOSHOOT: 'ROYALTY_REPORT_EXPORT_PHOTOSHOOT',
  EXPORT_KEYWORD: 'ROYALTY_REPORT_EXPORT_KEYWORD',
  EXPORT_SUCCESS: 'ROYALTY_REPORT_EXPORT_SUCCESS',
  listPhotoshoots: (search, authToken) => ({
    type: actions.LIST_PHOTOSHOOTS,
    search,
    authToken
  }),
  listPhotographers: (authToken) => ({
    type: actions.LIST_PHOTOGRAPHERS,
    authToken,
  }),
  listDistributors: (authToken) => ({
    type: actions.LIST_DISTRIBUTORS,
    authToken,
  }),
  listArtDirectors: (authToken) => ({
    type: actions.LIST_ART_DIRECTORS,
    authToken,
  }),
  listVerticals: (authToken) => ({
    type: actions.LIST_VERTICALS,
    authToken,
  }),
  listUsers: (search, authToken) => ({
    type: actions.LIST_USERS,
    search,
    authToken
  }),
  list: (params, authToken) => ({
    type: actions.LIST,
    params,
    authToken
  }),
  listByPhotoshoot: (params, authToken) => ({
    type: actions.LIST_BY_PHOTOSHOOT,
    params,
    authToken
  }),
  listByMonth: (params, authToken) => ({
    type: actions.LIST_BY_MONTH,
    params,
    authToken
  }),
  listByKeyword: (params, authToken) => ({
    type: actions.LIST_BY_KEYWORD,
    params,
    authToken
  }),
  get: (productId, productParams, authToken) => ({
    type: actions.GET,
    productId,
    productParams,
    authToken
  }),
  exportGeneric: (params, authToken) => ({
    type: actions.EXPORT_GENERIC,
    params,
    authToken
  }),
  exportVisual: (params, authToken) => ({
    type: actions.EXPORT_VISUAL,
    params,
    authToken
  }),
  exportProduct: (params, authToken) => ({
    type: actions.EXPORT_PRODUCT,
    params,
    authToken
  }),
  exportPhotoshoot: (params, authToken) => ({
    type: actions.EXPORT_PHOTOSHOOT,
    params,
    authToken
  }),
  exportKeyword: (params, authToken) => ({
    type: actions.EXPORT_KEYWORD,
    params,
    authToken
  })
};
export default actions;
