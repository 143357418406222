const actions = {
  LIST: 'ORDER_LIST',
  LIST_SUCCESS: 'ORDER_LIST_SUCCESS',
  GET: 'ORDER_GET',
  GET_SUCCESS: 'ORDER_GET_SUCCESS',
  CRUD_ERROR: 'ORDER_CRUD_ERROR',
  UPDATE: 'ORDER_UPDATE',
  UPDATE_SUCCESS: 'ORDER_UPDATE_SUCCESS',
  CREATE: 'ORDER_CREATE',
  CREATE_SUCCESS: 'ORDER_CREATE_SUCCESS',
  LIST_USERS: 'ORDER_LIST_USERS',
  LIST_USERS_SUCCESS: 'ORDER_LIST_USERS_SUCCESS',
  LIST_USERS_ERROR: 'ORDER_LIST_USERS_ERROR',
  REMOVE_ORDER_PRODUCT: 'ORDER_REMOVE_PRODUCT',
  RELOAD_ORDER_PRODUCTS: 'ORDER_RELOAD_PRODUCTS',
  ADD_ORDER_PRODUCT: 'ORDER_ADD_PRODUCT',
  ADD_ORDER_PRODUCT_SUCCESS: 'ORDER_ADD_PRODUCT_SUCCESS',
  ADD_ORDER_PRODUCT_ERROR: 'ORDER_ADD_PRODUCT_ERROR',

  LIST_PRODUCTS: 'ORDER_LIST_USERS', /* for list of products to select */
  LIST_PRODUCTS_SUCCESS: 'ORDER_LIST_PRODUCTS_SUCCESS',
  LIST_PRODUCTS_ERROR: 'ORDER_LIST_PRODUCTS_ERROR',
  LIST_PRICES: 'ORDER_LIST_PRICES',
  LIST_PRICES_SUCCESS: 'ORDER_LIST_PRICES_SUCCESS',
  LIST_PRICES_ERROR: 'ORDER_LIST_PRICES_ERROR',
  REMOVE_PRODUCT: 'ORDER_REMOVE_PRODUCT',
  REMOVE_PRODUCT_SUCCESS: 'ORDER_REMOVE_PRODUCT_SUCCESS',
  REMOVE_PRODUCT_ERROR: 'ORDER_REMOVE_PRODUCT_ERROR',
  
  list: (params, authToken) => ({
    type: actions.LIST,
    params,
    authToken
  }),
  get: (id, authToken) => ({
    type: actions.GET,
    id,
    authToken
  }),
  update: (id, fieldData, authToken) => ({
    type: actions.UPDATE,
    id,
    fieldData,
    authToken
  }),
  create: (fieldData, authToken) => ({
    type: actions.CREATE,
    fieldData,
    authToken
  }),
  listUsers: (authToken) => ({
    type: actions.LIST_USERS,
    authToken,
  }),
  listProducts: (authToken) => ({
    type: actions.LIST_PRODUCTS,
    authToken,
  }),
  listPrices: (authToken) => ({
    type: actions.LIST_PRICES,
    authToken,
  }),
  addProduct: (fieldData, authToken) => ({
    type: actions.ADD_ORDER_PRODUCT,
    fieldData,
    authToken
  }),
  removeProduct: (productOrderPriceId, authToken) => ({
    type: actions.REMOVE_PRODUCT,
    productOrderPriceId,
    authToken
  })
};
export default actions;
