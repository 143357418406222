/**
 * src/modules/auth/service.js
 */

import HeroApiHelper from '../../helpers/axios'

class Service extends HeroApiHelper {
  check(authToken) {
    return super.get('/user/check', {}, authToken)
  }

  login({email, password}) {
    return super.post('/user/login', {email, password})
  }

  register({email, password, newsletter}) {
    return super.post('/user/signUp', {email, password, newsletter})
  }

  forgetPassword({email}) {
    return super.post('/user/forgetPassword', {email})
  }

  verifyPassword({passwordToken}) {
    return super.get('/user/resetPassword', {passwordToken})
  }

  resetPassword({passwordToken, password}) {
    return super.put('/user/resetPassword', {passwordToken, password})
  }
}

export default new Service()