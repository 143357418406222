import actions from "./actions";

export function listReducer(state = {galleries: [], info: undefined}, action) {
  let newData
  let galleries

  switch (action.type) {
    case actions.LIST:
      return {
        ...state,
        galleries: [],
        info: undefined
      };
    case actions.LIST_SUCCESS:
      return {
        galleries: action.galleries,
        info: action.info
      };
    case actions.LIST_ERROR:
      return {
        galleries: [],
        info: undefined,
        type: action.type,
        error: action.error
      };
    case actions.UPDATE_SUCCESS:
      newData = state.galleries.findIndex(p => p.id === action.id)
      galleries = [...state.galleries]
      galleries[newData] = Object.assign({}, galleries[newData], { ...action.gallery, galleryProducts: action.gallery.galleryProducts.slice(0, 1), relatedGalleries: [] })
      return newData > 0 ? {
        galleries,
        info: state.info
      } : state;
    case actions.ORDER_GALLERY_PRODUCT_SUCCESS:
      newData = state.galleries.findIndex(p => p.id === action.id)
      galleries = [...state.galleries]
      galleries[newData] = Object.assign({}, galleries[newData], { galleryProducts: action.galleryProducts.slice(0, 1) })
      return newData > 0 ? {
        galleries,
        info: state.info
      } : state;
    default:
      return state;
  }
}

export function reducer(state = {gallery: undefined, info: undefined}, action) {
  switch (action.type) {
    case actions.GET:
      return {
        gallery: undefined,
        info: state.info
      };
    case actions.GET_SUCCESS:
      return {
        gallery: action.gallery,
        info: action.info
      };
    case actions.GET_ERROR:
      return {
        gallery: undefined,
        info: undefined,
        type: action.type,
        error: action.error
      };
    case actions.UPDATE_SUCCESS:
      return state.gallery && state.gallery.id === action.id ? {
        gallery: Object.assign({}, state.gallery, action.gallery),
        info: state.info
      } : state;
    case actions.ORDER_GALLERY_PRODUCT_SUCCESS:
      return state.gallery && state.gallery.id === action.id ? {
        gallery: { ...state.gallery, galleryProducts: action.galleryProducts },
        info: state.info
      } : state;
    case actions.ADD_RELATED_GALLERY_SUCCESS:
      return state.gallery && state.gallery.id === action.id ? {
        gallery: { ...state.gallery, relatedGalleries: [...state.gallery.relatedGalleries, action.relatedGallery] },
        info: state.info
      } : state;
    case actions.REMOVE_RELATED_GALLERY_SUCCESS:
      return state.gallery && state.gallery.id === action.id ? {
        gallery: { ...state.gallery, relatedGalleries: state.gallery.relatedGalleries.filter(g => g.id !== action.relatedGalleryId) },
        info: state.info
      } : state;
    case actions.CRUD_ERROR:
      return {
        gallery: state.gallery,
        info: undefined,
        type: action.type,
        error: action.error
      };
    default:
      return state;
  }
}
