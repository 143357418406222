const actions = {
  LIST_MODELS: 'PHOTOSHOOT_LIST_MODELS',
  LIST_MODELS_SUCCESS: 'PHOTOSHOOT_LIST_MODELS_SUCCESS',
  LIST_MODELS_ERROR: 'PHOTOSHOOT_LIST_MODELS_ERROR',
  LIST_PROPERTIES: 'PHOTOSHOOT_LIST_PROPERTIES',
  LIST_PROPERTIES_SUCCESS: 'PHOTOSHOOT_LIST_PROPERTIES_SUCCESS',
  LIST_PROPERTIES_ERROR: 'PHOTOSHOOT_LIST_PROPERTIES_ERROR',
  LIST_PHOTOGRAPHERS: 'PHOTOSHOOT_LIST_PHOTOGRAPHERS',
  LIST_PHOTOGRAPHERS_SUCCESS: 'PHOTOSHOOT_LIST_PHOTOGRAPHERS_SUCCESS',
  LIST_PHOTOGRAPHERS_ERROR: 'PHOTOSHOOT_LIST_PHOTOGRAPHERS_ERROR',
  LIST_ART_DIRECTORS: 'PHOTOSHOOT_LIST_ART_DIRECTORS',
  LIST_ART_DIRECTORS_SUCCESS: 'PHOTOSHOOT_LIST_ART_DIRECTORS_SUCCESS',
  LIST_ART_DIRECTORS_ERROR: 'PHOTOSHOOT_LIST_ART_DIRECTORS_ERROR',
  LIST_VERTICALS: 'PHOTOSHOOT_LIST_VERTICALS',
  LIST_VERTICALS_SUCCESS: 'PHOTOSHOOT_LIST_VERTICALS_SUCCESS',
  LIST_VERTICALS_ERROR: 'PHOTOSHOOT_LIST_VERTICALS_ERROR',
  LIST: 'PHOTOSHOOT_LIST',
  LIST_SUCCESS: 'PHOTOSHOOT_LIST_SUCCESS',
  LIST_ERROR: 'PHOTOSHOOT_LIST_ERROR',
  GET: 'PHOTOSHOOT_GET',
  GET_SUCCESS: 'PHOTOSHOOT_GET_SUCCESS',
  GET_ERROR: 'PHOTOSHOOT_GET_ERROR',
  CREATE: 'PHOTOSHOOT_CREATE',
  CREATE_SUCCESS: 'PHOTOSHOOT_CREATE_SUCCESS',
  UPDATE: 'PHOTOSHOOT_UPDATE',
  UPDATE_SUCCESS: 'PHOTOSHOOT_UPDATE_SUCCESS',
  DELETE: 'PHOTOSHOOT_DELETE',
  DELETE_SUCCESS: 'PHOTOSHOOT_DELETE_SUCCESS',
  CRUD_ERROR: 'PHOTOSHOOT_CRUD_ERROR',
  EXPORT_FORMAT: 'PHOTOSHOOT_EXPORT_FORMAT',
  EXPORT_TEMPLATE: 'PHOTOSHOOT_EXPORT_TEMPLATE',
  EXPORT_SUCCESS: 'PHOTOSHOOT_EXPORT_SUCCESS',
  IMPORT: 'PHOTOSHOOT_IMPORT',
  IMPORT_SUCCESS: 'PHOTOSHOOT_IMPORT_SUCCESS',
  SET_FEATURE: 'PHOTOSHOOT_SET_FEATURE',
  SET_FEATURE_SUCCESS: 'PHOTOSHOOT_SET_FEATURE_SUCCESS',
  REMOVE_PRODUCT: 'PHOTOSHOOT_REMOVE_PRODUCT',
  RANK_PRODUCTS: 'PHOTOSHOOT_RANK_PRODUCTS',
  RELOAD: 'PHOTOSHOOT_RELOAD',
  listModels: (search, authToken) => ({
    type: actions.LIST_MODELS,
    search,
    authToken
  }),
  listProperties: (search, authToken) => ({
    type: actions.LIST_PROPERTIES,
    search,
    authToken
  }),
  listPhotographers: (authToken) => ({
    type: actions.LIST_PHOTOGRAPHERS,
    authToken,
  }),
  listArtDirectors: (authToken) => ({
    type: actions.LIST_ART_DIRECTORS,
    authToken,
  }),
  listVerticals: (authToken) => ({
    type: actions.LIST_VERTICALS,
    authToken,
  }),
  list: (params, authToken) => ({
    type: actions.LIST,
    params,
    authToken
  }),
  get: (id, productParams, authToken) => ({
    type: actions.GET,
    id,
    productParams,
    authToken
  }),
  create: (fieldData, authToken) => ({
    type: actions.CREATE,
    fieldData,
    authToken
  }),
  update: (id, fieldData, authToken) => ({
    type: actions.UPDATE,
    id,
    fieldData,
    authToken
  }),
  delete: (id, authToken) => ({
    type: actions.DELETE,
    id,
    authToken
  }),
  exportFormat: (photoshootIds, format, authToken) => ({
    type: actions.EXPORT_FORMAT,
    photoshootIds,
    format,
    authToken
  }),
  exportTemplate: (id, authToken) => ({
    type: actions.EXPORT_TEMPLATE,
    id,
    authToken
  }),
  importMetadata: (id, file, authToken) => ({
    type: actions.IMPORT,
    id,
    file,
    authToken
  }),
  setFeature: (id, productId, authToken) => ({
    type: actions.SET_FEATURE,
    id,
    productId,
    authToken
  }),
  rankProducts: (photoshootId, productIds, rank, authToken) => ({
    type: actions.RANK_PRODUCTS,
    photoshootId,
    productIds,
    rank,
    authToken
  }),
  removeProduct: (id, productId, authToken) => ({
    type: actions.REMOVE_PRODUCT,
    id,
    productId,
    authToken
  })
};
export default actions;
