import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import service from './service';

export function* list() {
  yield takeLatest(actions.LIST, function*(action) {
    try {
      const response = yield call(({authToken, isTailored}) => service.list(authToken, isTailored), action)
      yield put({
        type: actions.LIST_SUCCESS,
        galleries: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* get() {
  yield takeEvery(actions.GET, function*(action) {
    try {
      const response = yield call((id) => service.get(id), action.id)
      yield put({
        type: actions.GET_SUCCESS,
        gallery: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.GET_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* create() {
  yield takeEvery(actions.CREATE, function*(action) {
    try {
      const response = yield call(({fieldData, authToken}) => service.create(fieldData, authToken), action)
      yield put({
        type: actions.CREATE_SUCCESS,
        id: response.data.info
      })
      yield put(push(`/dashboard/gallery/${response.data.info}`))
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* update() {
  yield takeEvery(actions.UPDATE, function*(action) {
    try {
      const response = yield call(({id, fieldData, authToken}) => service.update(id, fieldData, authToken), action)
      yield put({
        type: actions.UPDATE_SUCCESS,
        id: response.data.info,
        gallery: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* deleteGallery() {
  yield takeEvery(actions.DELETE, function*(action) {
    try {
      const response = yield call(({id, authToken}) => service.delete(id, authToken), action)
      yield put({
        type: actions.DELETE_SUCCESS,
        id: response.data.info
      })
      yield put(push(`/dashboard/galleries`))
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* orderGalleryProduct() {
  yield takeEvery(actions.ORDER_GALLERY_PRODUCT, function*(action) {
    try {
      const response = yield call(({id, movedProductId, destinationIndex, authToken}) => service.orderGalleryProduct(id, movedProductId, destinationIndex, authToken), action)
      yield put({
        type: actions.ORDER_GALLERY_PRODUCT_SUCCESS,
        id: response.data.info.id,
        galleryProducts: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* addRelatedGallery() {
  yield takeEvery(actions.ADD_RELATED_GALLERY, function*(action) {
    try {
      const response = yield call(({id, relatedGalleryId, authToken}) => service.addRelatedGallery(id, relatedGalleryId, authToken), action)
      yield put({
        type: actions.ADD_RELATED_GALLERY_SUCCESS,
        id: response.data.info,
        relatedGallery: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* removeRelatedGallery() {
  yield takeEvery(actions.REMOVE_RELATED_GALLERY, function*(action) {
    try {
      const response = yield call(({id, relatedGalleryId, authToken}) => service.removeRelatedGallery(id, relatedGalleryId, authToken), action)
      yield put({
        type: actions.REMOVE_RELATED_GALLERY_SUCCESS,
        id: response.data.info.id,
        relatedGalleryId: response.data.info.relatedGalleryId
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(list),
    fork(get),
    fork(create),
    fork(update),
    fork(deleteGallery),
    fork(orderGalleryProduct),
    fork(addRelatedGallery),
    fork(removeRelatedGallery)
  ]);
}
