import actions from "./actions";

const _defaultState = {
  error: undefined,
  objects: undefined,
  editObject: undefined,
  info: undefined,
  reload: undefined,
  loading: undefined,
  users: [],
  products: [],
  prices: [],
  newProduct: undefined
}

export default function reducer(state = _defaultState, action) {
  console.log("Action Type", action)
  switch (action.type) {
    case actions.LIST:
      return {
        ...state,
        objects: undefined,
        info: undefined,
        reload: undefined,
        loading: state.reload ? 'reload' : true
      }
    case actions.LIST_SUCCESS:
      console.log("%c Objects", "background-color: red", action.objects)
      return {
        ...state,
        error: undefined,
        objects: action.objects,
        info: action.info,
        loading: undefined
      };
    case actions.GET:
      return {
        ...state,
        editObject: undefined,
        info: undefined,
        reload: undefined,
        loading: state.reload ? 'reload' : true,
      };
    case actions.GET_SUCCESS:
      return {
        ...state,
        error: undefined,
        editObject: action.editObject,
        info: action.info,
        loading: undefined,
      };
    case actions.CRUD_ERROR:
      return {
        ...state,
        error: action.error,
        loading: undefined
      };
    case actions.CREATE_SUCCESS:
      return {
        ...state,
        editObject: action.editObject
      }
    case actions.UPDATE_SUCCESS:
      return {
        ...state,
        reload: true
      }
    case actions.LIST_USERS_SUCCESS:
      return {
        ...state,
        error: undefined,
        users: action.users
      };
    case actions.LIST_USERS_ERROR:
      return {
        ...state,
        error: action.error,
        users: []
      };
    case actions.LIST_PRODUCTS_SUCCESS:
      return {
        ...state,
        error: undefined,
        products: action.products
      };
    case actions.LIST_PRODUCTS_ERROR:
      return {
        ...state,
        error: action.error,
        products: []
      };
    case actions.LIST_PRICES_SUCCESS:
      return {
        ...state,
        error: undefined,
        prices: action.prices
      };
    case actions.LIST_PRICES_ERROR:
      return {
        ...state,
        error: action.error,
        prices: []
      };
    case actions.ADD_ORDER_PRODUCT_SUCCESS:
      return {
        ...state,
        error: undefined,
        newProduct: action.newProduct
      };
    case actions.ADD_ORDER_PRODUCT_ERROR:
      return {
        ...state,
        error: action.error
      };
    case actions.REMOVE_PRODUCT_SUCCESS:
      console.log("I'm in here")
      return {
        ...state,
        error: undefined,
        editObject: Object.assign({}, state.editObject, {
          reloadProducts: true
        })
      };
    case actions.REMOVE_PRODUCT_ERROR:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}
