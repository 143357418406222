import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import * as FileDownload from 'js-file-download'

import actions from './actions';
import service from './service';

import fileReader from '../../helpers/fileReader'

export function* listPhotoshoots() {
  yield takeLatest(actions.LIST_PHOTOSHOOTS, function*(action) {
    if (!action.search) {
      yield put({
        type: actions.LIST_PHOTOSHOOTS_SUCCESS,
        objects: [],
        info: undefined
      })
      return;
    }

    try {
      const response = yield call(({search, authToken}) => service.listPhotoshoots(search, authToken), action)
      yield put({
        type: actions.LIST_PHOTOSHOOTS_SUCCESS,
        objects: response.data.data,
        info: action.search
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST_PHOTOSHOOTS,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listPhotographers() {
  yield takeLatest(actions.LIST_PHOTOGRAPHERS, function*(action) {
    try {
      const response = yield call(({authToken}) => service.listPhotographers(authToken), action)
      yield put({
        type: actions.LIST_PHOTOGRAPHERS_SUCCESS,
        objects: response.data.data
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST_PHOTOGRAPHERS,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listDistributors() {
  yield takeLatest(actions.LIST_DISTRIBUTORS, function*(action) {
    try {
      const response = yield call(({authToken}) => service.listDistributors(authToken), action)
      yield put({
        type: actions.LIST_DISTRIBUTORS_SUCCESS,
        objects: response.data.data
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST_DISTRIBUTORS,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listArtDirectors() {
  yield takeLatest(actions.LIST_ART_DIRECTORS, function*(action) {
    try {
      const response = yield call(({authToken}) => service.listArtDirectors(authToken), action)
      yield put({
        type: actions.LIST_ART_DIRECTORS_SUCCESS,
        objects: response.data.data
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST_ART_DIRECTORS,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listVerticals() {
  yield takeLatest(actions.LIST_VERTICALS, function*(action) {
    try {
      const response = yield call(({authToken}) => service.listVerticals(authToken), action)
      yield put({
        type: actions.LIST_VERTICALS_SUCCESS,
        objects: response.data.data
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST_VERTICALS,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listUsers() {
  yield takeLatest(actions.LIST_USERS, function*(action) {
    if (!action.search) {
      yield put({
        type: actions.LIST_USERS_SUCCESS,
        objects: [],
        info: undefined
      })
      return;
    }

    try {
      const response = yield call(({search, authToken}) => service.listUsers(search, authToken), action)
      yield put({
        type: actions.LIST_USERS_SUCCESS,
        objects: response.data.data,
        info: action.search
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST_USERS,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* list() {
  yield takeLatest(actions.LIST, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.list(params, authToken), action)
      yield put({
        type: actions.LIST_SUCCESS,
        objects: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listByPhotoshoot() {
  yield takeLatest(actions.LIST_BY_PHOTOSHOOT, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.listByPhotoshoot(params, authToken), action)
      yield put({
        type: actions.LIST_BY_PHOTOSHOOT_SUCCESS,
        objects: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST_BY_PHOTOSHOOT,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listByMonth() {
  yield takeLatest(actions.LIST_BY_MONTH, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.listByMonth(params, authToken), action)
      yield put({
        type: actions.LIST_BY_MONTH_SUCCESS,
        objects: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST_BY_MONTH,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listByKeyword() {
  yield takeLatest(actions.LIST_BY_KEYWORD, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.listByKeyword(params, authToken), action)
      yield put({
        type: actions.LIST_BY_KEYWORD_SUCCESS,
        objects: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST_BY_KEYWORD,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* get() {
  yield takeEvery(actions.GET, function*(action) {
    try {
      const response = yield call(({productId, productParams, authToken}) => service.get(productId, productParams, authToken), action)
      yield put({
        type: actions.GET_SUCCESS,
        editObject: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        cause: actions.GET,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* exportGeneric() {
  yield takeEvery(actions.EXPORT_GENERIC, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.exportGeneric(params, authToken), action)
      FileDownload(response.data, response.headers['x-suggested-filename']);
      yield put({
        type: actions.EXPORT_SUCCESS
      })
    }
    catch (error){
      if (error.message) {
        yield put({
          cause: actions.EXPORT_GENERIC,
          type: actions.CRUD_ERROR,
          error: { message: error.message }
        })
        return;
      }
      const errorResponse = yield fileReader(error.response.data)
      const responseData = JSON.parse(errorResponse)
      yield put({
        cause: actions.EXPORT_GENERIC,
        type: actions.CRUD_ERROR,
        error: { message: (responseData && responseData.message) || error.message }
      })
    }
  });
}

export function* exportVisual() {
  yield takeEvery(actions.EXPORT_VISUAL, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.exportVisual(params, authToken), action)
      FileDownload(response.data, response.headers['x-suggested-filename']);
      yield put({
        type: actions.EXPORT_SUCCESS
      })
    }
    catch (error){
      if (error.message) {
        yield put({
          cause: actions.EXPORT_VISUAL,
          type: actions.CRUD_ERROR,
          error: { message: error.message }
        })
        return;
      }
      const errorResponse = yield fileReader(error.response.data)
      const responseData = JSON.parse(errorResponse)
      yield put({
        cause: actions.EXPORT_VISUAL,
        type: actions.CRUD_ERROR,
        error: { message: (responseData && responseData.message) || error.message }
      })
    }
  });
}

export function* exportProduct() {
  yield takeEvery(actions.EXPORT_PRODUCT, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.exportProduct(params, authToken), action)
      FileDownload(response.data, response.headers['x-suggested-filename']);
      yield put({
        type: actions.EXPORT_SUCCESS
      })
    }
    catch (error){
      if (error.message) {
        yield put({
          cause: actions.EXPORT_PRODUCT,
          type: actions.CRUD_ERROR,
          error: { message: error.message }
        })
        return;
      }
      const errorResponse = yield fileReader(error.response.data)
      const responseData = JSON.parse(errorResponse)
      yield put({
        cause: actions.EXPORT_PRODUCT,
        type: actions.CRUD_ERROR,
        error: { message: (responseData && responseData.message) || error.message }
      })
    }
  });
}

export function* exportPhotoshoot() {
  yield takeEvery(actions.EXPORT_PHOTOSHOOT, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.exportPhotoshoot(params, authToken), action)
      FileDownload(response.data, response.headers['x-suggested-filename']);
      yield put({
        type: actions.EXPORT_SUCCESS
      })
    }
    catch (error){
      if (error.message) {
        yield put({
          cause: actions.EXPORT_PHOTOSHOOT,
          type: actions.CRUD_ERROR,
          error: { message: error.message }
        })
        return;
      }
      const errorResponse = yield fileReader(error.response.data)
      const responseData = JSON.parse(errorResponse)
      yield put({
        cause: actions.EXPORT_PHOTOSHOOT,
        type: actions.CRUD_ERROR,
        error: { message: (responseData && responseData.message) || error.message }
      })
    }
  });
}

export function* exportKeyword() {
  yield takeEvery(actions.EXPORT_KEYWORD, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.exportKeyword(params, authToken), action)
      FileDownload(response.data, response.headers['x-suggested-filename']);
      yield put({
        type: actions.EXPORT_SUCCESS
      })
    }
    catch (error){
      if (error.message) {
        yield put({
          cause: actions.EXPORT_KEYWORD,
          type: actions.CRUD_ERROR,
          error: { message: error.message }
        })
        return;
      }
      const errorResponse = yield fileReader(error.response.data)
      const responseData = JSON.parse(errorResponse)
      yield put({
        cause: actions.EXPORT_KEYWORD,
        type: actions.CRUD_ERROR,
        error: { message: (responseData && responseData.message) || error.message }
      })
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(listPhotoshoots),
    fork(listPhotographers),
    fork(listDistributors),
    fork(listArtDirectors),
    fork(listVerticals),
    fork(listUsers),
    fork(list),
    fork(listByPhotoshoot),
    fork(listByMonth),
    fork(listByKeyword),
    fork(get),
    fork(exportGeneric),
    fork(exportVisual),
    fork(exportProduct),
    fork(exportPhotoshoot),
    fork(exportKeyword)
  ]);
}
