import actions from "./actions";

export default function reducer(state = {
  products: [],
  editProduct: undefined, 
  info: undefined,
  type: undefined,
  error: undefined
}, action) {
  switch (action.type) {
    case actions.LIST:
      return {
        ...state,
        products: [],
        info: undefined
      }
    case actions.LIST_SUCCESS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        products: action.products,
        info: action.info
      };
    case actions.LIST_ERROR:
      return {
        ...state,
        type: action.type,
        error: action.error
      };
    case actions.GET:
      return {
        ...state,
        editProduct: undefined
      };
    case actions.GET_SUCCESS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        editProduct: action.editProduct
      };
    case actions.GET_ERROR:
      return {
        ...state,
        type: action.type,
        error: action.error,
        editProduct: undefined
      };
    case actions.UPDATE_SUCCESS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        editProduct: action.editProduct
      };
    case actions.CRUD_ERROR:
      return {
        ...state,
        type: action.type,
        error: action.error,
      };
    case actions.RELOAD:
      return {
        ...state,
        type: undefined,
        error: undefined,
        products: undefined
      };
    default:
      return state;
  }
}
