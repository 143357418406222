import HeroApiHelper from '../../helpers/axios'

class Service extends HeroApiHelper {

  listDistributors(authToken) {
    return super.get(`admin/royaltyImport/distributor`, { imports: true }, authToken)
  }
  
  listCurrencies(authToken) {
    return super.get(`admin/royaltyImport/currency`, {}, authToken)
  }
  
  listRoyaltyPeriods(distributorId, authToken) {
    return super.get(`admin/royaltyImport/royaltyperiod`, {distributorId}, authToken)
  }

  list(params, authToken) {
    return super.get(`admin/royaltyimport`, params, authToken)
  }

  get(id, lineParams, authToken) {
    const defaultParams = {
      lineOffset: 0,
      linePageSize: 50,
      where: {
        search: undefined
      },
      order: undefined
    }

    return super.get(`admin/royaltyimport/${id}`, lineParams || defaultParams, authToken)
  }

  create(fieldData, file, authToken) {
    const data = new FormData()
    data.append('distributorId', fieldData.distributorId)
    data.append('royaltyPeriod', fieldData.royaltyPeriod)
    data.append('file', file, file.name)
    return super.post('/admin/royaltyimport', data, authToken)
  }

  update(id, file, authToken) {
    const data = new FormData()
    data.append('file', file, file.name)
    return super.patch(`/admin/royaltyimport/${id}`, data, authToken)
  }

  delete(id, authToken) {
    return super.delete(`/admin/royaltyimport/${id}`, authToken)
  }

  exportTemplate(authToken) {
    return super.getBlob(`/admin/royaltyimport/template/generic`, {}, authToken)
  }
}

export default new Service()