import actions from "./actions";

const _defaultState = {
  error: undefined,
  objects: undefined,
  editObject: undefined,
  orders: undefined,
  shoots: undefined,
  info: undefined,
  reload: undefined,
  loading: undefined,
  orderReload: undefined,
  orderLoading: undefined
}

export default function reducer(state = _defaultState, action) {
  switch (action.type) {
    case actions.LIST_ORDER:
      return {
        ...state,
        orders: undefined,
        info: undefined,
        orderReload: undefined,
        orderLoading: state.orderReload ? 'reload' : true
      }
    case actions.LIST_ORDER_SUCCESS:
      return {
        ...state,
        error: undefined,
        orders: action.objects,
        info: action.info,
        orderLoading: undefined
      };
    case actions.LIST:
      return {
        ...state,
        objects: undefined,
        info: undefined,
        reload: undefined,
        loading: state.reload ? 'reload' : true
      }
    case actions.LIST_SUCCESS:
      return {
        ...state,
        error: undefined,
        objects: action.objects,
        info: action.info,
        loading: undefined
      };
    case actions.GET:
      return {
        ...state,
        editObject: undefined,
        orders: undefined,
        shoots: undefined,
        info: undefined,
        reload: undefined,
        loading: state.reload ? 'reload' : true
      };
    case actions.GET_SUCCESS:
      return {
        ...state,
        error: undefined,
        editObject: action.editObject,
        loading: undefined
      };
    case actions.UPDATE_SUCCESS:
      return {
        ...state,
        reload: true
      }
    case actions.CREATE_SUCCESS:
      return {
        ...state,
        editObject: action.editObject
      }
    case actions.CRUD_ERROR:
      return {
        ...state,
        orders: undefined,
        shoots: undefined,
        error: action.error,
        loading: undefined,
        orderLoading: undefined
      };
    case actions.RELOAD_ORDERS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        editObect: action.editObject,
        orderReload: true,
        orderLoading: state.orderReload ? 'reload' : true,
        editObject: Object.assign({}, state.editObject, {
          userOrders: undefined
        })
      };
    default:
      return state;
  }
}
