import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: 'galleries',
    component: asyncComponent(() => import('../Gallery')),
  },
  {
    path: 'tailoredGalleries',
    component: asyncComponent(() => import('../Gallery')),
  },
  {
    path: 'tailoredBriefs',
    component: asyncComponent(() => import('../TailoredBriefs')),
  },
  {
    path: 'gallery/:galleryId',
    component: asyncComponent(() => import('../Gallery/edit')),
  },
  {
    path: 'coupons',
    component: asyncComponent(() => import('../Coupon')),
  },
  {
    path: 'coupon',
    component: asyncComponent(() => import('../Coupon/edit')),
  },
  {
    path: 'coupon/:couponId',
    component: asyncComponent(() => import('../Coupon/edit')),
  },
  {
    path: 'usercredits',
    component: asyncComponent(() => import('../UserCredit')),
  },
  {
    path: 'usercredits/:userId',
    component: asyncComponent(() => import('../UserCredit/user')),
  },
  {
    path: 'usercredit/:userCreditId',
    component: asyncComponent(() => import('../UserCredit/edit')),
  },
  {
    path: 'orders',
    component: asyncComponent(() => import('../Order')),
  },
  {
    path: 'order',
    component: asyncComponent(() => import('../Order/edit')),
  },
  {
    path: 'order/:orderId',
    component: asyncComponent(() => import('../Order/edit')),
  },
  {
    path: 'users',
    component: asyncComponent(() => import('../User')),
  },
  {
    path: 'user',
    component: asyncComponent(() => import('../User/edit')),
  },
  {
    path: 'user/:userId',
    component: asyncComponent(() => import('../User/edit')),
  },
  {
    path: 'models',
    component: asyncComponent(() => import('../Model')),
  },
  {
    path: 'model',
    component: asyncComponent(() => import('../Model/edit')),
  },
  {
    path: 'model/:modelId',
    component: asyncComponent(() => import('../Model/edit')),
  },
  {
    path: 'properties',
    component: asyncComponent(() => import('../Property')),
  },
  {
    path: 'property',
    component: asyncComponent(() => import('../Property/edit')),
  },
  {
    path: 'property/:propertyId',
    component: asyncComponent(() => import('../Property/edit')),
  },
  {
    path: 'photoshoots',
    component: asyncComponent(() => import('../Photoshoot')),
  },
  {
    path: 'photoshoot',
    component: asyncComponent(() => import('../Photoshoot/edit')),
  },
  {
    path: 'photoshoot/:photoshootId',
    component: asyncComponent(() => import('../Photoshoot/edit')),
  },
  {
    path: 'products',
    component: asyncComponent(() => import('../Product')),
  },
  {
    path: 'product/:productId',
    component: asyncComponent(() => import('../Product/edit')),
  },
  {
    path: 'royaltyimports',
    roleGroup: ['superAdmin'],
    component: asyncComponent(() => import('../RoyaltyImport')),
  },
  {
    path: 'royaltyimport',
    roleGroup: ['superAdmin'],
    component: asyncComponent(() => import('../RoyaltyImport/edit')),
  },
  {
    path: 'royaltyimport/:royaltyImportId',
    roleGroup: ['superAdmin'],
    component: asyncComponent(() => import('../RoyaltyImport/edit')),
  },
  {
    path: 'royaltyreports',
    roleGroup: ['superAdmin', 'artDirectorAdmin'],
    component: asyncComponent(() => import('../RoyaltyReport')),
  },
  {
    path: 'royaltyreport/:productId',
    roleGroup: ['superAdmin'],
    component: asyncComponent(() => import('../RoyaltyReport/edit'))
  }
];

class AppRouter extends Component {
  render() {
    const { url, style, roleGroup } = this.props;
    return (
      <div style={style}>
        {routes.filter(route => !route.roleGroup || route.roleGroup.includes(roleGroup)).map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
