import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import * as FileDownload from 'js-file-download'

import actions from './actions';
import service from './service';

import fileReader from '../../helpers/fileReader'

export function* list() {
  yield takeLatest(actions.LIST, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.list(params, authToken), action)
      yield put({
        type: actions.LIST_SUCCESS,
        models: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* get() {
  yield takeEvery(actions.GET, function*(action) {
    try {
      const response = yield call(({id, params, authToken}) => service.get(id, params, authToken), action)
      yield put({
        type: actions.GET_SUCCESS,
        editModel: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.GET_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* create() {
  yield takeEvery(actions.CREATE, function*(action) {
    try {
      const response = yield call(({fieldData, authToken}) => service.create(fieldData, authToken), action)
      yield put({
        type: actions.CREATE_SUCCESS,
        editModel: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* update() {
  yield takeEvery(actions.UPDATE, function*(action) {
    try {
      const response = yield call(({id, fieldData, authToken}) => service.update(id, fieldData, authToken), action)
      yield put({
        type: actions.UPDATE_SUCCESS,
        editModel: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* updateHeadshots() {
  yield takeEvery(actions.UPDATE_HEADSHOTS, function*(action) {
    try {
      const response = yield call(({id, fieldData, authToken}) => service.update(id, fieldData, authToken), action)
      const {id, headshots, headshotUrl, headshotRotation} = response.data.data 
      yield put({
        type: actions.UPDATE_HEADSHOTS_SUCCESS,
        viewingModel: {id, headshots, headshotUrl, headshotRotation}
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* deleteModel() {
  yield takeEvery(actions.DELETE, function*(action) {
    try {
      const response = yield call(({id, authToken}) => service.delete(id, authToken), action)
      yield put({
        type: actions.DELETE_SUCCESS,
        id: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* mergeModel() {
  yield takeEvery(actions.MERGE, function*(action) {
    try {
      const response = yield call(({id, duplicateModelId, authToken}) => service.merge(id, duplicateModelId, authToken), action)
      yield put({
        type: actions.MERGE_SUCCESS,
        models: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listHeadshots() {
  yield takeLatest(actions.LIST_HEADSHOTS, function*(action) {
    try {
      const response = yield call(({id, authToken}) => service.listHeadshots(id, authToken), action)
      yield put({
        type: actions.LIST_HEADSHOTS_SUCCESS,
        headshots: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* uploadHeadshots() {
  yield takeEvery(actions.UPLOAD_HEADSHOTS, function*(action) {
    try {
      const response = yield call(({id, headshots, authToken}) => service.uploadHeadshots(id, headshots, authToken), action)
      yield put({
        type: actions.UPLOAD_SUCCESS,
        headshots: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* downloadHeadshot() {
  yield takeEvery(actions.DOWNLOAD_HEADSHOT, function*(action) {
    try {
      const response = yield call(({id, headshotId, authToken}) => service.downloadHeadshot(id, headshotId, authToken), action)
      const fileResponse = yield call(({data}) => service.downloadSignedUrl(data.data), response)
      FileDownload(fileResponse.data, response.data.info);
      yield put({
        type: actions.DOWNLOAD_SUCCESS
      })
    }
    catch (error){
      if (error.message) {
        yield put({
          cause: actions.DOWNLOAD_HEADSHOT,
          type: actions.CRUD_ERROR,
          error: { message: error.message }
        })
        return;
      }
      const errorResponse = yield fileReader(error.response.data)
      try {
        const responseData = JSON.parse(errorResponse)
        yield put({
          cause: actions.DOWNLOAD_HEADSHOT,
          type: actions.CRUD_ERROR,
          error: { message: (responseData && responseData.message) }
        })
      }
      catch (e) {
        const parser = new DOMParser();
        const responseDoc = parser.parseFromString(errorResponse, 'text/xml')
        yield put({
          cause: actions.DOWNLOAD_HEADSHOT,
          type: actions.CRUD_ERROR,
          error: { message: (responseDoc && responseDoc.getElementsByTagName("Error")[0].childNodes[1].textContent) }
        })
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(list),
    fork(get),
    fork(create),
    fork(update),
    fork(updateHeadshots),
    fork(deleteModel),
    fork(mergeModel),
    fork(listHeadshots),
    fork(uploadHeadshots),
    fork(downloadHeadshot)
  ]);
}
