const siteConfig = {
    siteName: 'Hero Admin',
    siteIcon: 'ion-flash',
    footerText: '© 2018 Hero Images. All rights reserved.'
};

const themeConfig = {
    topbar: 'themedefault',
    sidebar: 'themedefault',
    layout: 'themedefault',
    theme: 'themedefault'
};
const language = 'english';
const heroConfig = {
    timeout: 60000,

    redirectTimeout: 10000,
    apiHost: process.env.REACT_APP_API_HOST,
    mainSiteUrl: process.env.REACT_APP_MAIN_SITE_URL || 'https://heroimages.com'
};
export {
    siteConfig,
    themeConfig,
    language,
    heroConfig
};