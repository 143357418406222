import actions from "./actions";

export default function reducer(state = {properties: [], editProperty: undefined, info: undefined}, action) {
  let actionIndex
  let properties

  switch (action.type) {
    case actions.LIST_SUCCESS:
      return {
        properties: action.properties,
        info: action.info,
        editProperty: state.editProperty
      };
    case actions.LIST_ERROR:
      return {
        properties: [],
        info: undefined,
        editProperty: state.editProperty,
        type: action.type,
        error: action.error
      };

    default:
      return state;
  }
}
