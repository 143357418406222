import HeroApiHelper from '../../helpers/axios'

class Service extends HeroApiHelper {
  list(params, authToken) {
    return super.get(`admin/property`, params, authToken)
  }

  get(id, params, authToken) {
    return super.get(`admin/property/${id}`, params, authToken)
  }

  create(fieldData, authToken) {
    const {address, sampleUrl, upload} = fieldData
    return super.post('/admin/property', {address, sampleUrl, upload}, authToken)
  }

  update(id, fieldData, authToken) {
    const {address, sampleUrl, upload} = fieldData
    return super.patch(`/admin/property/${id}`, {address, sampleUrl, upload}, authToken)
  }

  delete(id, authToken) {
    return super.delete(`/admin/property/${id}`, authToken)
  }

  merge(id, duplicatePropertyId, authToken) {
    return super.patch(`/admin/property/duplicates/${id}`, {duplicatePropertyId}, authToken)
  }
}

export default new Service()