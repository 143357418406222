import actions from "./actions";

export default function reducer(state = {
  distributors: [],
  currencies: [],
  royaltyPeriods: [],
  objects: [],
  editObject: undefined,
  info: undefined,
  error: undefined
}, action) {
  let actionIndex
  let objects

  switch (action.type) {
    case actions.LIST_DISTRIBUTORS:
      return {
        ...state,
        distributors: []
      }
    case actions.LIST_DISTRIBUTORS_SUCCESS:
      return {
        ...state,
        error: undefined,
        distributors: action.objects
      };
    case actions.LIST_CURRENCIES:
      return {
        ...state,
        currencies: []
      }
    case actions.LIST_CURRENCIES_SUCCESS:
      return {
        ...state,
        error: undefined,
        currencies: action.objects
      };
    case actions.LIST_ROYALTY_PERIODS:
      return {
        ...state,
        royaltyPeriods: undefined
      }
    case actions.LIST_ROYALTY_PERIODS_SUCCESS:
      return {
        ...state,
        error: undefined,
        royaltyPeriods: action.objects
      };
    case actions.LIST:
      return {
        ...state,
        objects: [],
        info: undefined
      }
    case actions.LIST_SUCCESS:
      return {
        ...state,
        error: undefined,
        objects: action.objects,
        info: action.info
      };
    case actions.GET:
      return {
        ...state,
        editObject: undefined,
        info: undefined
      };
    case actions.GET_SUCCESS:
      return {
        ...state,
        error: undefined,
        editObject: action.editObject,
        info: action.info
      };
    case actions.DELETE_SUCCESS:
      actionIndex = state.objects.findIndex(obj => obj.id === action.id)
      objects = [...state.objects]
      if (actionIndex !== -1) {
        objects.splice(actionIndex, 1)
      }
      return {
        ...state,
        error: undefined,
        objects,
        info: state.info && state.info.total ? {...state.info, total: state.info.total - 1} : state.info
      };
    case actions.CRUD_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
