const actions = {
  LIST_BUNDLE_USERS: 'CREDIT_LIST_BUNDLE_USERS',
  LIST_BUNDLE_USERS_SUCCESS: 'CREDIT_LIST_BUNDLE_USERS_SUCCESS',
  LIST_BUNDLE_USERS_ERROR: 'CREDIT_LIST_BUNDLE_USERS_ERROR',
  LIST_PRICES: 'CREDIT_LIST_PRICES',
  LIST_PRICES_SUCCESS: 'CREDIT_LIST_PRICES_SUCCESS',
  LIST_PRICES_ERROR: 'CREDIT_LIST_PRICES_ERROR',
  LIST_USERS: 'CREDIT_LIST_USERS',
  LIST_USERS_SUCCESS: 'CREDIT_LIST_USERS_SUCCESS',
  LIST_USERS_ERROR: 'CREDIT_LIST_USERS_ERROR',
  LIST: 'CREDIT_LIST',
  LIST_SUCCESS: 'CREDIT_LIST_SUCCESS',
  LIST_ERROR: 'CREDIT_LIST_ERROR',
  GET: 'CREDIT_GET',
  GET_SUCCESS: 'CREDIT_GET_SUCCESS',
  GET_ERROR: 'CREDIT_GET_ERROR',
  CREATE: 'CREDIT_CREATE',
  CREATE_SUCCESS: 'CREDIT_CREATE_SUCCESS',
  UPDATE: 'CREDIT_UPDATE',
  UPDATE_SUCCESS: 'CREDIT_UPDATE_SUCCESS',
  DELETE: 'CREDIT_DELETE',
  DELETE_SUCCESS: 'CREDIT_DELETE_SUCCESS',
  CRUD_ERROR: 'CREDIT_CRUD_ERROR',
  listBundleUsers: (params, authToken) => ({
    type: actions.LIST_BUNDLE_USERS,
    params,
    authToken
  }),
  listPrices: () => ({
    type: actions.LIST_PRICES
  }),
  listUsers: (params, authToken) => ({
    type: actions.LIST_USERS,
    params,
    authToken
  }),
  list: (params, authToken) => ({
    type: actions.LIST,
    params,
    authToken
  }),
  get: (id, authToken) => ({
    type: actions.GET,
    id,
    authToken
  }),
  create: (fieldData, authToken) => ({
    type: actions.CREATE,
    fieldData,
    authToken
  }),
  update: (id, fieldData, authToken) => ({
    type: actions.UPDATE,
    id,
    fieldData,
    authToken
  })
};
export default actions;
