import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getToken, clearToken } from '../../helpers/utility';
import actions from './actions';
import service from './service';

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*(action) {
    try {
      const response = yield call((creds) => service.login(creds), action.creds)
      if (response.data.data.user.role !== 'admin') {
        yield put({
          type: actions.LOGIN_FAILURE,
          profile: response.data.data.user,
          error: { message: 'this site requires administrator access' }
        })
      }
      else {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: response.data.data.token,
          profile: response.data.data.user
        })
        yield put(push(action.redirectUrl))
      }
    }
    catch (error){
      yield put({
        type: actions.LOGIN_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken();
    yield put(push('/'));
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken().get('idToken');
    if (token && token !== 'undefined') {
      yield put({
        type: actions.LOGIN_OFFLINE,
        token
      });
      try {
        const response = yield call((token) => service.check(token), token)
        if (response.data.data.user.role !== 'admin') {
          yield put({
            type: actions.LOGIN_FAILURE,
            profile: response.data.data.user,
            error: { message: 'this site requires administrator access' }
          })
        }
        else {
          yield put({
            type: actions.CHECK_AUTHORIZATION_SUCCESS,
            profile: response.data.data.user
          });
        }
      }
      catch (error){
        if (error.response && error.response.status < 500) {
          yield put({
            type: actions.LOGIN_ERROR,
            error: { message: (error.response && error.response.data.message) || error.message }
          })
          clearToken()
        }
        else {
          yield put({
            type: actions.LOGIN_FAILURE,
            token,
            error: { message: (error.response && error.response.data.message) || error.message }
          })
        }
      }
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(loginRequest),
    fork(loginSuccess),
    fork(logout),
    fork(checkAuthorization)
  ]);
}
