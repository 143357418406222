import { getDefaultPath } from "../../helpers/urlSync";
import actions from "./actions";

const preKeys = getDefaultPath();

const initState = {
  collapsed: true,
  openDrawer: false,
  view: 'MobileView',
  openKeys: preKeys,
  current: preKeys
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.COLLPSE_CHANGE:
      return {
        ...state,
        collapsed: !state.collapsed,
        openDrawer: false
      };
    case actions.COLLPSE_OPEN_DRAWER:
      return {
        ...state,
        openDrawer: !state.openDrawer
      };
    case actions.CHANGE_VIEW:
      return {
        ...state,
        collapsed: action.collapsed,
        view: action.view
      };
    case actions.CHANGE_OPEN_KEYS:
      return {
        ...state,
        openKeys: action.openKeys
      };
    case actions.CHANGE_CURRENT:
      return {
        ...state,
        current: action.current
      };
    case actions.CLEAR_MENU:
      return {
        ...state,
        openKeys: [],
        current: []
      };
    default:
      return state;
  }
}
