import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import service from './service';

export function* list() {
  yield takeLatest(actions.LIST, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.list(params, authToken), action)
      yield put({
        type: actions.LIST_SUCCESS,
        objects: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* get() {
  yield takeLatest(actions.GET, function*(action) {
    try {
      const response = yield call(({id, authToken}) => service.get(id, authToken), action)
      yield put({
        type: actions.GET_SUCCESS,
        editObject: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        cause: actions.GET,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* update() {
  yield takeEvery(actions.UPDATE, function*(action) {
    try {
      console.log("I'm right here - action:", action);
      const response = yield call(({id, fieldData, authToken}) => service.update(id, fieldData, authToken), action)

      yield put({
        type: actions.UPDATE_SUCCESS,
        editObject: response.data.data
      })
    }
    catch (error){
      yield put({
        cause: actions.UPDATE,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* create() {
  yield takeEvery(actions.CREATE, function*(action) {
    try {
      const response = yield call(({fieldData, authToken}) => service.create(fieldData, authToken), action)

      yield put({
        type: actions.CREATE_SUCCESS,
        editObject: response.data.data
      })
    }
    catch (error){
      yield put({
        cause: actions.CREATE,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listUsers() {
  yield takeLatest(actions.LIST_USERS, function*(action) {
    try {
      const response = yield call(({authToken}) => service.listUsers(authToken), action)
      yield put({
        type: actions.LIST_USERS_SUCCESS,
        users: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_USERS_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listProducts() {
  yield takeLatest(actions.LIST_PRODUCTS, function*(action) {
    try {
      const response = yield call(({authToken}) => service.listProducts(authToken), action)
      yield put({
        type: actions.LIST_PRODUCTS_SUCCESS,
        products: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_PRODUCTS_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listPrices() {
  yield takeLatest(actions.LIST_PRICES, function*(action) {
    try {
      const response = yield call(({authToken}) => service.listPrices(authToken), action)
      yield put({
        type: actions.LIST_PRICES_SUCCESS,
        prices: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.LIST_PRICES_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* addProduct() {
  yield takeLatest(actions.ADD_ORDER_PRODUCT, function*(action) {
    try {
      const response = yield call(({fieldData, authToken}) => service.addProduct(fieldData, authToken), action)
      yield put({
        type: actions.ADD_ORDER_PRODUCT_SUCCESS,
        newProduct: response.data.data
      })
    }
    catch (error){
      yield put({
        type: actions.ADD_ORDER_PRODUCT_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* removeProduct() {
  yield takeEvery(actions.REMOVE_PRODUCT, function*(action) {
    try {
      yield call(({productOrderPriceId, authToken}) => service.removeProduct(productOrderPriceId, authToken), action)

      yield put({
        type: actions.REMOVE_PRODUCT_SUCCESS,
        cause: actions.REMOVE_PRODUCT,
      })
    }
    catch (error){
      yield put({
        type: actions.REMOVE_PRODUCT_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(list),
    fork(get),
    fork(update),
    fork(create),
    fork(listUsers),
    fork(listProducts),
    fork(listPrices),
    fork(addProduct),
    fork(removeProduct)
  ]);
}
