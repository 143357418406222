import HeroApiHelper from '../../helpers/axios'

class Service extends HeroApiHelper {
  list(authToken, isTailored = false, offset = 0, limit = 1000) {
    return super.get(`/gallery?pageSize=${limit}&offset=${offset}`, {isTailored}, authToken)
  }

  get(id) {
    return super.get(`/gallery/${id}?pageSize=200`)
  }

  create(fieldData, authToken) {
    const {title, isActive, isTailored} = fieldData
    return super.post('/admin/gallery', {title, isActive, isTailored}, authToken)
  }

  update(id, fieldData, authToken) {
    const {title, isActive, isFeatured, homePageFeaturePosition } = fieldData
    return super.put(`/admin/gallery/${id}`, {title, isActive, isFeatured, homePageFeaturePosition}, authToken)
  }

  delete(id, authToken) {
    return super.delete(`/admin/gallery/${id}`, authToken)
  }

  orderGalleryProduct(id, movedProductId, destinationIndex, authToken) {
    return super.put(`/admin/gallery/${id}/product/${movedProductId}`, {destinationIndex}, authToken)
  }

  addRelatedGallery(id, galleryId, authToken) {
    return super.post(`/admin/gallery/${id}/related/${galleryId}`, {}, authToken)
  }

  removeRelatedGallery(id, galleryId, authToken) {
    return super.delete(`/admin/gallery/${id}/related/${galleryId}`, authToken)
  }
}

export default new Service()