import actions from "./actions";

export default function reducer(state = {properties: [], editProperty: undefined, info: undefined}, action) {
  let actionIndex
  let properties

  switch (action.type) {
    case actions.MERGE:
      return {
        ...state,
        loading: true
      }
    case actions.MERGE_SUCCESS:
      return {
        properties: action.properties,
        info: action.info,
        editProperty: state.editProperty,
        loading: false
      };
    case actions.LIST_SUCCESS:
      return {
        properties: action.properties,
        info: action.info,
        editProperty: state.editProperty
      };
    case actions.LIST_ERROR:
      return {
        properties: [],
        info: undefined,
        editProperty: state.editProperty,
        type: action.type,
        error: action.error
      };
    case actions.GET:
      return {
        properties: state.properties,
        info: state.info,
        editProperty: undefined
      };
    case actions.GET_SUCCESS:
      return {
        properties: state.properties,
        info: action.info,
        editProperty: action.editProperty
      };
    case actions.DELETE_SUCCESS:
      actionIndex = state.properties.findIndex(p => p.id === action.id)
      properties = [...state.properties]
      if (actionIndex !== -1) {
        properties.splice(actionIndex, 1)
      }
      return {
        properties,
        info: state.info,
        editProperty: undefined
      };
    case actions.GET_ERROR:
      return {
        properties: state.properties,
        info: state.info,
        editProperty: undefined,
        type: action.type,
        error: action.error
      };
    case actions.CREATE_SUCCESS:
    case actions.UPDATE_SUCCESS:
      if (state.editProperty) {
        action.editProperty.products = [...state.editProperty.products]
      }
      return {
        ...state,
        type: undefined,
        error: undefined,
        editProperty: action.editProperty
      };
    case actions.CRUD_ERROR:
      return {
        properties: state.properties,
        info: state.info,
        editProperty: state.editProperty,
        type: action.type,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
}
