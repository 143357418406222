const actions = {
  LIST: 'USER_LIST',
  LIST_SUCCESS: 'USER_LIST_SUCCESS',
  LIST_ORDER: 'USER_LIST_ORDER',
  LIST_ORDER_SUCCESS: 'USER_LIST_ORDER_SUCCESS',
  GET: 'USER_GET',
  GET_SUCCESS: 'USER_GET_SUCCESS',
  UPDATE: 'USER_UPDATE',
  UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  CREATE: 'USER_CREATE',
  CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
  CRUD_ERROR: 'USER_CRUD_ERROR',
  REMOVE_ORDER: 'USER_REMOVE_ORDER',
  RELOAD_ORDERS: 'USER_RELOAD_ORDERS',
  
  orderList: (params, authToken) => ({
    type: actions.LIST_ORDER,
    params,
    authToken
  }),
  list: (params, authToken) => ({
    type: actions.LIST,
    params,
    authToken
  }),
  get: (id, authToken) => ({
    type: actions.GET,
    id,
    authToken
  }),
  update: (id, fieldData, authToken) => ({
    type: actions.UPDATE,
    id,
    fieldData,
    authToken
  }),
  create: (fieldData, authToken) => ({
    type: actions.CREATE,
    fieldData,
    authToken
  }),
  removeOrder: (orderId, authToken) => ({
    type: actions.REMOVE_ORDER,
    orderId,
    authToken
  })
};
export default actions;
