const actions = {
  LIST: 'COUPON_LIST',
  LIST_SUCCESS: 'COUPON_LIST_SUCCESS',
  LIST_ERROR: 'COUPON_LIST_ERROR',
  GET: 'COUPON_GET',
  GET_SUCCESS: 'COUPON_GET_SUCCESS',
  GET_ERROR: 'COUPON_GET_ERROR',
  CREATE: 'COUPON_CREATE',
  CREATE_SUCCESS: 'COUPON_CREATE_SUCCESS',
  UPDATE: 'COUPON_UPDATE',
  UPDATE_SUCCESS: 'COUPON_UPDATE_SUCCESS',
  DELETE: 'COUPON_DELETE',
  DELETE_SUCCESS: 'COUPON_DELETE_SUCCESS',
  CRUD_ERROR: 'COUPON_CRUD_ERROR',

  LIST_USERS: 'COUPON_LIST_USERS',
  LIST_USERS_SUCCESS: 'COUPON_LIST_USERS_SUCCESS',
  LIST_USERS_ERROR: 'COUPON_LIST_USERS_ERROR',
  list: (params, authToken) => ({
    type: actions.LIST,
    params,
    authToken
  }),
  get: (id, authToken) => ({
    type: actions.GET,
    id,
    authToken
  }),
  create: (fieldData, authToken) => ({
    type: actions.CREATE,
    fieldData,
    authToken
  }),
  update: (id, fieldData, authToken) => ({
    type: actions.UPDATE,
    id,
    fieldData,
    authToken
  }),
  delete: (id, authToken) => ({
    type: actions.DELETE,
    id,
    authToken
  }),
  listUsers: (search, authToken) => ({
    type: actions.LIST_USERS,
    search,
    authToken
  }),
};
export default actions;
