import actions from "./actions";

export default function reducer(state = {
    userSearch: undefined,
    userResults: [],
    coupons: [],
    editCoupon: undefined,
    info: undefined}, action) {

  let actionIndex
  let coupons

  switch (action.type) {
    case actions.LIST_USERS_SUCCESS:
      return {
        ...state,
        type: undefined,
        error: undefined,
        userSearch: action.info,
        userResults: action.userResults
      };
    case actions.LIST_USERS_ERROR:
      return {
        ...state,
        type: action.type,
        error: action.error,
        userSearch: undefined,
        userResults: []
      };
    case actions.LIST_SUCCESS:
      return {
        coupons: action.coupons,
        info: action.info,
        editCoupon: state.editCoupon
      };
    case actions.LIST_ERROR:
      return {
        coupons: [],
        info: undefined,
        editCoupon: state.editCoupon,
        type: action.type,
        error: action.error
      };
    case actions.GET:
      return {
        coupons: state.coupons,
        info: state.info,
        editCoupon: undefined
      };
    case actions.GET_SUCCESS:
      return {
        coupons: state.coupons,
        info: state.info,
        editCoupon: action.editCoupon
      };
    case actions.DELETE_SUCCESS:
      actionIndex = state.coupons.findIndex(p => p.id === action.id)
      coupons = [...state.coupons]
      if (actionIndex !== -1) {
        coupons.splice(actionIndex, 1)
      }
      return {
        coupons,
        info: state.info,
        editCoupon: undefined
      };
    case actions.GET_ERROR:
      return {
        coupons: state.coupons,
        info: state.info,
        editCoupon: undefined,
        type: action.type,
        error: action.error
      };
    case actions.CREATE_SUCCESS:
    case actions.UPDATE_SUCCESS:
      actionIndex = state.coupons.findIndex(p => p.id === action.editCoupon.id)
      coupons = [...state.coupons]
      coupons[actionIndex !== -1 ? actionIndex : coupons.length] = action.editCoupon
      return {
        coupons,
        info: state.info,
        editCoupon: action.editCoupon
      };
    case actions.CRUD_ERROR:
      return {
        coupons: state.coupons,
        info: state.info,
        editCoupon: state.editCoupon,
        type: action.type,
        error: action.error
      };
    default:
      return state;
  }
}
