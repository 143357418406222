import HeroApiHelper from '../../helpers/axios'

class Service extends HeroApiHelper {
  orderList(params, authToken) {
    return super.get(`order`, params, authToken)
  }

  list(params, authToken) {
    return super.get(`admin/user`, params, authToken)
  }

  get(id, authToken) {
    return super.get(`admin/user/${id}`, {}, authToken)
  }

  update(id, fieldData, authToken) {
    return super.put(`/admin/user/${id}`, fieldData, authToken)
  }

  create(fieldData, authToken) {
    return super.post(`/admin/user`, fieldData, authToken)
  }
  
  removeOrder(orderId, authToken){
    return super.delete(`/order/${orderId}`, authToken)
  }
}

export default new Service()