import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import authAction from '../../redux/auth/actions';
import appAction from '../../redux/app/actions';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';
import { siteConfig } from '../../settings';
import { AppLocale } from '../../dashApp';
import themes from '../../settings/themes';
import notification from '../../components/notification';
import AppHolder from './commonStyle';
import './global.css';

const { Content, Footer } = Layout;
const { changeView } = appAction;
const { logout } = authAction;
export class App extends Component {
  componentDidMount() {
    this.updateView()
    window.addEventListener("resize", this.updateView);
    if (this.props.auth.error) {
      notification('warning', 'app is offline: ' + this.props.auth.error.message);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.auth.error && (this.props.auth.error !== prevProps.auth.error || this.props.location !== prevProps.location)) {
      notification('warning', 'app is offline: ' + this.props.auth.error.message);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateView);
  }
  updateView = () => {
    let newView = "MobileView";
    if (window.innerWidth > 1220) {
      newView = "DesktopView";
    } else if (window.innerWidth > 767) {
      newView = "TabView";
    }
    
    if (this.props.view !== newView) {
      this.props.changeView(newView)
    }
    return newView;
  }
  render() {
    const { url } = this.props.match;
    const { locale, selectedTheme } = this.props;
    const currentAppLocale = AppLocale[locale];
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[selectedTheme]}>
            <AppHolder>
              <Layout style={{ height: '100vh' }}>
                <Topbar url={url} />
                <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                  <Sidebar url={url} />
                  <Layout
                    className="isoContentMainLayout"
                    style={{ height: 'calc(100vh-70px)', marginTop: 70 }}
                  >
                    <Content
                      className="heroContent"
                      style={{
                        margin: '0',
                        flexShrink: '0',
                        background: '#f1f3f6',
                        position: 'relative'
                      }}
                    >
                      <AppRouter url={url} roleGroup={this.props.auth.profile && this.props.auth.profile.roleGroup} />
                    </Content>
                    <Footer
                      style={{
                        background: '#ffffff',
                        textAlign: 'center',
                        borderTop: '1px solid #ededed'
                      }}
                    >
                      {siteConfig.footerText}
                    </Footer>
                  </Layout>
                </Layout>
              </Layout>
            </AppHolder>
          </ThemeProvider>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

export default connect(
  state => ({
    auth: state.Auth,
    view: state.App.view,
    locale: state.LanguageSwitcher.language.locale,
    selectedTheme: state.ThemeSwitcher.changeThemes.themeName
  }),
  { logout, changeView }
)(App);
