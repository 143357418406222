import actions from "./actions";

export default function reducer(state = {
  photoshootSearch: undefined,
  photoshoots: [],
  photographers: [],
  distributors: [],
  artDirectors: [],
  verticals: [],
  userSearch: undefined,
  users: [],
  objects: [],
  reportByPhotoshoot: [],
  reportByMonth: [],
  reportByKeyword: [],
  editObject: undefined,
  info: undefined,
  photoshootInfo: undefined,
  monthInfo: undefined,
  keywordInfo: undefined,
  error: undefined
}, action) {

  switch (action.type) {
    case actions.LIST_PHOTOSHOOTS:
      return {
        ...state,
        photoshoots: []
      }
    case actions.LIST_PHOTOSHOOTS_SUCCESS:
      return {
        ...state,
        error: undefined,
        photoshootSearch: action.info,
        photoshoots: action.objects
      };
    case actions.LIST_PHOTOGRAPHERS:
      return {
        ...state,
        photographers: []
      }
    case actions.LIST_PHOTOGRAPHERS_SUCCESS:
      return {
        ...state,
        error: undefined,
        photographers: action.objects
      };
    case actions.LIST_DISTRIBUTORS:
      return {
        ...state,
        distributors: []
      }
    case actions.LIST_DISTRIBUTORS_SUCCESS:
      return {
        ...state,
        error: undefined,
        distributors: action.objects
      };
    case actions.LIST_ART_DIRECTORS:
      return {
        ...state,
        artDirectors: []
      }
    case actions.LIST_ART_DIRECTORS_SUCCESS:
      return {
        ...state,
        error: undefined,
        artDirectors: action.objects
      };
    case actions.LIST_VERTICALS:
      return {
        ...state,
        verticals: []
      }
    case actions.LIST_VERTICALS_SUCCESS:
      return {
        ...state,
        error: undefined,
        verticals: action.objects
      };
    case actions.LIST_USERS:
      return {
        ...state,
        users: []
      }
    case actions.LIST_USERS_SUCCESS:
      return {
        ...state,
        error: undefined,
        userSearch: action.info,
        users: action.objects
      };
    case actions.LIST:
      return {
        ...state,
        objects: undefined,
        info: undefined
      }
    case actions.LIST_SUCCESS:
      return {
        ...state,
        error: undefined,
        objects: action.objects,
        info: action.info
      };
    case actions.LIST_BY_PHOTOSHOOT:
      return {
        ...state,
        reportByPhotoshoot: undefined,
        photoshootInfo: undefined
      }
    case actions.LIST_BY_PHOTOSHOOT_SUCCESS:
      return {
        ...state,
        error: undefined,
        reportByPhotoshoot: action.objects,
        photoshootInfo: action.info
      }
    case actions.LIST_BY_MONTH:
      return {
        ...state,
        reportByMonth: undefined,
        monthInfo: undefined
      }
    case actions.LIST_BY_MONTH_SUCCESS:
      return {
        ...state,
        error: undefined,
        reportByMonth: action.objects,
        monthInfo: action.info
      }
    case actions.LIST_BY_KEYWORD:
      return {
        ...state,
        reportByKeyword: undefined,
        keywordInfo: undefined
      }
    case actions.LIST_BY_KEYWORD_SUCCESS:
      return {
        ...state,
        error: undefined,
        reportByKeyword: action.objects,
        keywordInfo: action.info
      }
    case actions.GET:
      return {
        ...state,
        editObject: undefined,
        info: undefined
      };
    case actions.GET_SUCCESS:
      return {
        ...state,
        error: undefined,
        editObject: action.editObject,
        info: action.info
      };
    case actions.EXPORT_GENERIC:
      return {
        ...state,
        exporting: 'generic'
      }
    case actions.EXPORT_VISUAL:
      return {
        ...state,
        exporting: 'visual'
      }
    case actions.EXPORT_PRODUCT:
      return {
        ...state,
        exporting: 'product'
      }
    case actions.EXPORT_PHOTOSHOOT:
      return {
        ...state,
        exporting: 'photoshoot'
      }
    case actions.EXPORT_KEYWORD:
      return {
        ...state,
        exporting: 'keyword'
      }
    case actions.EXPORT_SUCCESS:
      return {
        ...state,
        exporting: undefined
      }
    case actions.CRUD_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
