import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import gallery from './gallery/saga';
import coupon from './coupon/saga';
import userCredit from './userCredit/saga';
import order from './order/saga';
import user from './user/saga';
import model from './model/saga';
import property from './property/saga';
import photoshoot from './photoshoot/saga';
import product from './product/saga';
import royaltyImport from './royaltyImport/saga';
import royaltyReport from './royaltyReport/saga';
import tailoredBrief from './tailoredBrief/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    gallery(),
    coupon(),
    userCredit(),
    order(),
    user(),
    model(),
    property(),
    photoshoot(),
    product(),
    royaltyImport(),
    royaltyReport(),
    tailoredBrief(),
  ]);
}
