import HeroApiHelper from '../../helpers/axios'

class Service extends HeroApiHelper {
  listPhotoshoots(search, authToken) {
    return super.get(`admin/photoshoot/simple`, { where: { search } }, authToken)
  }

  listPhotographers(authToken) {
    return super.get(`admin/photoshoot/photographer`, {}, authToken)
  }

  listDistributors(authToken) {
    return super.get(`admin/royaltyImport/distributor`, {}, authToken)
  }

  listArtDirectors(authToken) {
    return super.get(`admin/photoshoot/artdirector`, {}, authToken)
  }

  listVerticals(authToken) {
    return super.get(`admin/photoshoot/vertical`, {}, authToken)
  }

  listUsers(search, authToken) {
    return super.get(`admin/user/simple`, { where: { search } }, authToken)
  }

  list(params, authToken) {
    return super.get(`admin/royaltyreport`, params, authToken)
  }

  listByPhotoshoot(params, authToken) {
    return super.get(`admin/royaltyreport/photoshoot`, params, authToken)
  }

  listByMonth(params, authToken) {
    return super.get(`admin/royaltyreport/month`, params, authToken)
  }

  listByKeyword(params, authToken) {
    return super.get(`admin/royaltyreport/keyword`, params, authToken)
  }

  get(productId, productParams, authToken) {
    return super.get(`admin/royaltyreport/${productId}`, productParams, authToken)
  }

  exportGeneric(params, authToken) {
    return super.getBlob(`/admin/royaltyreport/export/generic`, params, authToken)
  }

  exportVisual(params, authToken){
    return super.getSlowBlob(`/admin/royaltyreport/export/visual`, params, authToken)
  }

  exportProduct(params, authToken){
    return super.getBlob(`/admin/royaltyreport/export/product`, params, authToken)
  }

  exportPhotoshoot(params, authToken){
    return super.getBlob(`/admin/royaltyreport/export/photoshoot`, params, authToken)
  }

  exportKeyword(params, authToken){
    return super.getBlob(`/admin/royaltyreport/export/keyword`, params, authToken)
  }
}

export default new Service()