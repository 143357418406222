import HeroApiHelper from '../../helpers/axios'

class Service extends HeroApiHelper {
    list(params, authToken) {
        return super.get(`admin/model`, params, authToken)
    }

    get(id, params, authToken) {
        return super.get(`admin/model/${id}`, params, authToken)
    }

    create(fieldData, authToken) {
        const { contactNumber, email, firstName, lastName, type, gender, dateOfBirth, ethnicityWhite, ethnicityMultiRacial, ethnicityBlack, ethnicityAsian, ethnicityHispanic, ethnicityMiddleEastern, ethnicitySouthAsian, ethnicityOther, headshotUrl, headshotRotation, headshots, isDeleted, comments } = fieldData
        return super.post('/admin/model', { contactNumber, email, firstName, lastName, type, gender, dateOfBirth, ethnicityWhite, ethnicityMultiRacial, ethnicityBlack, ethnicityAsian, ethnicityHispanic, ethnicityMiddleEastern, ethnicitySouthAsian, ethnicityOther, headshotUrl, headshotRotation, headshots, isDeleted, comments }, authToken)
    }

    update(id, fieldData, authToken) {
        const { contactNumber, email, firstName, lastName, type, gender, dateOfBirth, ethnicityWhite, ethnicityMultiRacial, ethnicityBlack, ethnicityAsian, ethnicityHispanic, ethnicityMiddleEastern, ethnicitySouthAsian, ethnicityOther, headshotUrl, headshotRotation, headshots, isDeleted, comments } = fieldData
        return super.patch(`/admin/model/${id}`, { contactNumber, email, firstName, lastName, type, gender, dateOfBirth, ethnicityWhite, ethnicityMultiRacial, ethnicityBlack, ethnicityAsian, ethnicityHispanic, ethnicityMiddleEastern, ethnicitySouthAsian, ethnicityOther, headshotUrl, headshotRotation, headshots, isDeleted, comments }, authToken)
    }

    updateHeadshots(id, fieldData, authToken) {
        const { headshotUrl, headshotRotation, headshots } = fieldData
        return super.patch(`/admin/model/headshot/${id}`, { headshotUrl, headshotRotation, headshots }, authToken)
    }

    delete(id, authToken) {
        return super.delete(`/admin/model/${id}`, authToken)
    }

    merge(id, duplicateModelId, authToken) {
        return super.patch(`/admin/model/duplicates/${id}`, { duplicateModelId }, authToken)
    }

    listHeadshots(id, authToken) {
        return super.get(`admin/model/headshot/list/${id}`, {}, authToken)
    }

    uploadHeadshots(id, headshots, authToken) {
        return super.post(`/admin/model/headshot/${id}`, { headshots }, authToken)
    }

    downloadHeadshot(id, headshotId, authToken) {
        return super.get(`/admin/model/headshot/${id}`, { headshotId }, authToken)
    }

    downloadSignedUrl(url) {
        return super.getBlob(url, {}, undefined);
    }
}

export default new Service()