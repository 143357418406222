const actions = {
  LIST: 'MODEL_LIST',
  LIST_SUCCESS: 'MODEL_LIST_SUCCESS',
  LIST_ERROR: 'MODEL_LIST_ERROR',
  GET: 'MODEL_GET',
  GET_SUCCESS: 'MODEL_GET_SUCCESS',
  GET_ERROR: 'MODEL_GET_ERROR',
  CREATE: 'MODEL_CREATE',
  CREATE_SUCCESS: 'MODEL_CREATE_SUCCESS',
  UPDATE: 'MODEL_UPDATE',
  UPDATE_SUCCESS: 'MODEL_UPDATE_SUCCESS',
  UPDATE_HEADSHOTS: 'MODEL_UPDATE_HEADSHOTS',
  UPDATE_HEADSHOTS_SUCCESS: 'MODEL_UPDATE_HEADSHOTS_SUCCESS',
  DELETE: 'MODEL_DELETE',
  DELETE_SUCCESS: 'MODEL_DELETE_SUCCESS',
  MERGE: 'MODEL_MERGE',
  MERGE_SUCCESS: 'MODEL_MERGE_SUCCESS',
  CRUD_ERROR: 'MODEL_CRUD_ERROR',
  LIST_HEADSHOTS: 'MODEL_LIST_HEADSHOTS',
  LIST_HEADSHOTS_SUCCESS: 'MODEL_LIST_HEADSHOTS_SUCCESS',
  UPLOAD_HEADSHOTS: 'MODEL_UPLOAD_HEADSHOTS',
  UPLOAD_SUCCESS: 'MODEL_UPLOAD_SUCCESS',
  DOWNLOAD_HEADSHOT: 'MODEL_DOWNLOAD_HEADSHOT',
  DOWNLOAD_SUCCESS: 'MODEL_DOWNLOAD_SUCCESS',
  list: (params, authToken) => ({
    type: actions.LIST,
    params,
    authToken
  }),
  get: (id, params, authToken) => ({
    type: actions.GET,
    id,
    params,
    authToken
  }),
  create: (fieldData, authToken) => ({
    type: actions.CREATE,
    fieldData,
    authToken
  }),
  update: (id, fieldData, authToken) => ({
    type: actions.UPDATE,
    id,
    fieldData,
    authToken
  }),
  updateHeadshots: (id, fieldData, authToken) => ({
    type: actions.UPDATE_HEADSHOTS,
    id,
    fieldData,
    authToken
  }),
  delete: (id, authToken) => ({
    type: actions.DELETE,
    id,
    authToken
  }),
  merge: (id, duplicateModelId, authToken) => ({
    type: actions.MERGE,
    id,
    duplicateModelId,
    authToken
  }),
  listHeadshots: (id, authToken) => ({
    type: actions.LIST_HEADSHOTS,
    id,
    authToken
  }),
  uploadHeadshots: (id, headshots, authToken) => ({
    type: actions.UPLOAD_HEADSHOTS,
    id,
    headshots,
    authToken
  }),
  downloadHeadshot: (id, headshotId, authToken) => ({
    type: actions.DOWNLOAD_HEADSHOT,
    id,
    headshotId,
    authToken
  })
};
export default actions;
