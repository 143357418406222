import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import * as FileDownload from 'js-file-download'

import actions from './actions';
import service from './service';

export function* listDistributors() {
  yield takeLatest(actions.LIST_DISTRIBUTORS, function*(action) {
    try {
      const response = yield call(({authToken}) => service.listDistributors(authToken), action)
      yield put({
        type: actions.LIST_DISTRIBUTORS_SUCCESS,
        objects: response.data.data
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST_DISTRIBUTORS,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listCurrencies() {
  yield takeLatest(actions.LIST_CURRENCIES, function*(action) {
    try {
      const response = yield call(({authToken}) => service.listCurrencies(authToken), action)
      yield put({
        type: actions.LIST_CURRENCIES_SUCCESS,
        objects: response.data.data
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST_CURRENCIES,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* listRoyaltyPeriods() {
  yield takeLatest(actions.LIST_ROYALTY_PERIODS, function*(action) {
    try {
      const response = yield call(({distributorId, authToken}) => service.listRoyaltyPeriods(distributorId, authToken), action)
      yield put({
        type: actions.LIST_ROYALTY_PERIODS_SUCCESS,
        objects: response.data.data
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST_ROYALTY_PERIODS,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* list() {
  yield takeLatest(actions.LIST, function*(action) {
    try {
      const response = yield call(({params, authToken}) => service.list(params, authToken), action)
      yield put({
        type: actions.LIST_SUCCESS,
        objects: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        cause: actions.LIST,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* get() {
  yield takeEvery(actions.GET, function*(action) {
    try {
      const response = yield call(({id, lineParams, authToken}) => service.get(id, lineParams, authToken), action)
      yield put({
        type: actions.GET_SUCCESS,
        editObject: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        cause: actions.GET,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* create() {
  yield takeEvery(actions.CREATE, function*(action) {
    try {
      // create
      const createResponse = yield call(({fieldData, file, authToken}) => service.create(fieldData, file, authToken), action)
      const id = createResponse.data.data
      
      // initial load
      const response = yield call(({lineParams, authToken}) => service.get(id, lineParams, authToken), action)
      yield put({
        cause: actions.CREATE_SUCCESS,
        type: actions.GET_SUCCESS,
        editObject: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        cause: actions.CREATE,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* update() {
  yield takeEvery(actions.UPDATE, function*(action) {
    try {
      // update
      yield call(({id, file, authToken}) => service.update(id, file, authToken), action)

      // reload
      const response = yield call(({id, lineParams, authToken}) => service.get(id, lineParams, authToken), action)
      yield put({
        cause: actions.UPDATE_SUCCESS,
        type: actions.GET_SUCCESS,
        editObject: response.data.data,
        info: response.data.info
      })
    }
    catch (error){
      yield put({
        cause: actions.UPDATE,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* deleteObject() {
  yield takeEvery(actions.DELETE, function*(action) {
    try {
      const response = yield call(({id, authToken}) => service.delete(id, authToken), action)
      yield put({
        type: actions.DELETE_SUCCESS,
        id: response.data.info
      })
    }
    catch (error){
      yield put({
        cause: actions.DELETE,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export function* exportTemplate() {
  yield takeEvery(actions.EXPORT_TEMPLATE, function*(action) {
    try {
      const response = yield call(({authToken}) => service.exportTemplate(authToken), action)
      FileDownload(response.data, `Generic_royalty_template.xlsx`);
      yield put({
        type: actions.EXPORT_SUCCESS
      })
    }
    catch (error){
      yield put({
        cause: actions.EXPORT_TEMPLATE,
        type: actions.CRUD_ERROR,
        error: { message: (error.response && error.response.data.message) || error.message }
      })
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(listDistributors),
    fork(listCurrencies),
    fork(listRoyaltyPeriods),
    fork(list),
    fork(get),
    fork(create),
    fork(update),
    fork(deleteObject),
    fork(exportTemplate)
  ]);
}
